<template>
    <div class="app-container top-line" :class="$route.meta.class">
        <div class="width-master">
            <div class="page-breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div
            class="page-banner"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>

                <div class="join-us__master">
                    <div class="join-us__master-ht">
                        <div class="icon-homso">
                            <i class="homso-bg"></i>
                        </div>
                        <div class="text-homso">
                            <div class="text-homso__name">
                                雄塑 HOMSO
                            </div>
                            <div class="text-homso__city">
                                上市公司 | 多个城市
                            </div>
                        </div>
                    </div>
                    <div class="join-us__master-hc">
                        <p>广东雄塑科技集团股份有限公司是一家集研发、生产和销售塑料管道的高新技术企业。公司连续多年被评为“广东省制造业500强”，2015年荣膺行业 “企业五星品牌”，2017年1月成功上市。因公司发展需要，现诚聘优秀人才。</p>
                    </div>
                    <div class="join-us__master-hf">
                        <h3>招聘说明</h3>
                        <br>
                        <p>1、公司拥有环境优美的宿舍、饭堂、篮球场、图书馆、电影室、儿童乐园、文体活动室等；
                            <br> 2、公司提供吃住，每间宿舍有电热水器和空调，有独立阳台和洗手间；
                        <br> 3、购买社保和公积金。</p>
                        <br>
                        <h3>联系方式</h3>
                        <br>
                        <p>1、公司拥有环境优美的宿舍、饭堂、篮球场、图书馆、电影室、儿童乐园、文体活动室等；
                            <br> 2、公司提供吃住，每间宿舍有电热水器和空调，有独立阳台和洗手间；
                        <br> 3、购买社保和公积金。</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-block width-master">

            <div class="join-us__header">
                <h2>所有招聘列表</h2>
                <div class="join-us__search">
                     <el-input
                        placeholder="搜索职位"
                        prefix-icon="el-icon-search"
                        v-model="input2">
                    </el-input>
                </div>
            </div>

            <div class="join-us__list">
                <div class="jobs-list">
                    <div :name="index + ''" v-for="(item, index) in Array(3)" :key="index" class="jobs-list__item">
                        <div class="jobs-list__item-header">
                            <div class="jobs-list__item-bl">
                                <div class="jobs-list__item-title">{{ list[0].title }}</div>
                                <div class="jobs-list__item-salary">16-25K</div>
                            </div>
                            <div class="jobs-list__item-bl">
                                <div class="jobs-list__item-exp"> 10年经验/大专/全职 </div>
                                <div class="jobs-list__item-pos">广州佛山</div>
                            </div>
                            <i class="el-icon-plus"></i>
                        </div>
                        <div class="jobs-list__item-desc" v-html="list[0].desc"></div>
                    </div>

                    <div class="jobs-list__empty">
                        <div class="empty-title">暂无待招岗位</div>
                        <div class="empty-desc">你可持续关注我们的招聘信息</div>
                        <div class="empty-action">
                            <a href="" class="cb-primary">返回上一页</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<style lang="scss" scoped>
    .join-us {
        .page-banner {
            background-image: url('~@/assets/image/join-us.jpg');
            height: 900px;
        }

        .page-block {
            max-width: 1000px;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            padding: 50px 0 25px;
            align-items: center;
            h2 {
                font-size: 24px;
            }
        }

        &__master {
            width: 940px;
            text-align: left;
            font-size: 14px;

            &-ht {
                display: flex;
                align-items: center;
                padding-bottom: 30px;
            }

            &-hc {
                padding-bottom: 30px;
            }

            &-hf {
                // h3 {
                //     padding-bottom: 15px;
                // }
            }

            .icon-homso {
                width: 80px;
                height: 80px;
                background: #005093;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .homso-bg {
                    width: 100%;
                    height: 38px;
                    background: url('~@/assets/image/logo-2@2x.png') no-repeat;
                    background-position: center center;
                    background-size: cover;
                }
            }

            .text-homso {
                padding-left: 15px;
                &__name {
                    font-size: 24px;
                }
            }
        }
    }

    .jobs-list {
        &__item {

            border: 1px solid #E8E8E8;
            margin-bottom: 20px;
            padding: 0 30px 20px;

            &-header {
                padding: 20px 0;
                box-sizing: border-box;
                height: 100px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                position: relative;
                padding-right: 50px;

                .el-icon-plus {
                    position: absolute;
                    right: 0;
                    font-weight: bold;
                    color: #1B70F0;
                    border: 1px dashed #ccc;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    line-height: 20px;
                    font-size: 18px;
                }
            }
            &-bl {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }

            &-title, &-salary {
                font-size: 18px;
            }

            &-exp, &-pos {
                font-size: 14px;
            }

            .fade-enter-active, .fade-leave-active {
                transition: opacity .5s;
            }
            .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
                opacity: 0;
            }
        }

        &__empty {
            background-image: url('~@/assets/image/join-us-empty.png');
            background-repeat: no-repeat;
            background-size: 90% auto;
            background-position: top center;
            width: 320px;
            padding-top: 320px;
            text-align: center;
            margin: 0 auto;
            padding-bottom: 50px;

            .empty-title {
                font-size: 28px;
                padding-bottom: 20px;
            }
            .empty-desc {
                padding-bottom: 10px;
            }
        }
        
    }
    .mobile {
        .join-us {
            .page-banner {
                background: #fff;
                width: 100vw;
                height: auto;

                &__title {
                    display: none;
                }
                &__subtitle {
                    display: none;
                }
                &__inside {
                    width: 90vw;
                    padding: 0;
                    margin: 0 auto;
                }

                .join-us__master {
                    width: 100%;
                    font-size: 12px;
                    line-height: 1.6;
                    &-ht {
                       padding-bottom: 5vw;
                    }

                    .icon-homso {
                        width: 12vw;
                        height:12vw;
                        border-radius: 6px;
                        .homso-bg {
                            height: 5vw;
                        }
                    }
                    .text-homso {
                        padding-left: 10px;
                        &__name {
                            font-size: 12px;
                        }
                        &__city{
                            font-size: 10px;
                        }
                    }
                }
            }
            
            &__header {
                width: 100vw;
                padding: 3vw 5vw;
                margin-top: 5vw;
                background: #e8e8e8;
                h2 {
                    font-size: 16px;
                }
            }

            &__search {
                display: none;
            }
        }
        .jobs-list {
            &__item {
                font-size: 11px;
                border: 0 none;
                border-top: 1px solid #e8e8e8;

                &-header {
                    height: auto;

                    .el-icon-plus {
                        width: 5vw;
                        height: 5vw;
                        font-size: 4vw;
                        line-height: 5vw;
                    }
                }

                &-title {
                    font-size: 14px;
                }
                
                &-exp, &-pos, &-salary {
                    font-size: 10px;
                    color: #999;
                }
                &-salary {
                    font-size: 12px;
                    color: #1B70F0;
                }
                &-desc {
                    line-height: 1.6;
                }
            }
        }
    }
</style>

<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem, Collapse, CollapseItem, Input } from "element-ui";
Vue.use(Breadcrumb).use(BreadcrumbItem).use(Collapse).use(CollapseItem).use(Input);

export default {
    name: "NewsList",
    components: {
    },
    mounted() {
        this.activeName = "0";
    },
    data() {
        return {
            name: "NewsList",
            activeName: '1',
            list: [
                {
                    id: 1,
                    title: "研发主任",
                    sub_title: "",
                    date: "2021-03-24",
                    
                    desc: `<p>招聘人数：1人</p>
<p>岗位薪资：12K-15K</p>
<p>岗位职责：</p>
<p>1、协助制定公司中长期技术发展和新产品开发战略，编制年度研发工作计划。</p>
<p>2、组织实施研发项目的市场调查、评审及可行性研究。</p>
<p>3、负责组织研发项目的立项、实施和评审。</p>
<p>4、负责组织进行生产技术难题的攻克工作，负责中心人员的日常管理和资源的分配。</p>
<p>&nbsp;</p>
<p>任职资格：</p>
<p>1、具有扎实的高分子材料合成、加工的理论知识。</p>
<p>2、具有丰富的塑料管道制造行业工作经验和组织研发开发新项目的工作经验。</p>
<p>3、具有八年以上的同行业经验。</p>`,
                    url: "https://fun2ex.com",
                    image: "https://temp.im/800x514/007AFF/fff",
                    dark_model: false,
                },
            ],
        };
    },
};
</script>
<style lang="scss">
</style>
