import axios from "axios";
// import Vue from 'vue'

const defaultConfig = {
    timeout: 10000,
    // headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    // },
    // 这里根据自己的需要配置
    // baseURL: process.env.NODE_ENV === 'development' ? '/' : '/site-freestyle-libre'
};

axios.defaults.baseURL = "/wp-json";
axios.post["Content-type"] = "application/x-www-form-urlencoded";
// let token;

// if (process.env.NODE_ENV === "development") {
//     token = "0123456";
// }

// prevent CSRF attack
if (process.env.NODE_ENV === 'prd' && window.csrfToken) {
  defaultConfig.headers['X-CSRF-TOKEN'] = window.csrfToken
}

// class Ajax {
//     constructor() {
//         this.queue = {};
//     }

//     destroy(url) {
//         delete this.queue[url];
//     }

//     interceptors(instance, url) {
//         // ajax global exception process
//         instance.interceptors.response.use(
//             // request, config
//             ({ data = {}, request }) => {
//                 // 提前销毁重复请求
//                 this.destroy(url);
//                 // 对响应数据做点什么
//                 // 业务状态码及URL校验
//                 if (
//                     data.code !== 200 &&
//                     data.errorCode !== 0 &&
//                     request.responseURL &&
//                     !request.responseURL.match(/auth/)
//                 ) {
//                     let msg = data.msg;
//                     if (!msg) {
//                         msg = "数据获取异常";
//                     }
//                     // 全局提示，自定义
//                 }
//                 return data;
//             },
//             (error) => {
//                 // 全局提示，自定义
//                 return Promise.reject(error);
//             }
//         );
//         instance.interceptors.request.use((config) => {
//             // if (token) {
//             //     config.headers.authorization = "Bearer " + token;
//             // }
//             return config;
//         });
//     }

//     request(options) {
//         const instance = axios.create(defaultConfig);
//         this.interceptors(instance);
//         options = Object.assign({}, options);
//         this.queue[options.url] = instance;
//         return instance(options);
//     }
// }

export default axios;