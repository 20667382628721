<template>
    <div class="home">
        <HomeCarousel />
        <div class="his-block">
            <div class="his-block__inside width-master">
                <div class="his-block__item his-block__item-1">
                    <h2>我们的优势</h2>
                    <div class="desc">做塑胶精品，树国际品牌</div>
                </div>
                <div class="his-block__item his-block__item-2">
                    <div class="picture"></div>
                    <h2>起步于 1996 年</h2>
                    <div class="desc">用心做好每一根管道</div>
                </div>
                <div class="his-block__item his-block__item-3">
                    <div class="picture"></div>
                    <h2>自主研发专利 110+</h2>
                    <div class="desc">拥有丰富的技术储备</div>
                </div>
                <div class="his-block__item his-block__item-4">
                    <div class="picture"></div>
                    <h2>稳定经销商 500+</h2>
                    <div class="desc">营销网络优势</div>
                </div>
            </div>
        </div>

        <div class="hd hd-about__homso">
            <div class="hd-inside">
                <div class="ht">
                    <div class="meta">
                        <i class="line"></i>
                        <span class="text">ABOUT HOMSO</span>
                    </div>
                    <h2>关于雄塑</h2>
                </div>

                <div class="hc">
                    <div class="news-block">
                        <div class="news-block__title">
                            <h3>新闻资讯</h3>
                        </div>
                        <div class="news-block__content">
                            <div
                                class="news-block__left news-block__child"
                                :style="`background-image: url(${$publicPath}/static/banner/home-n-1.jpg);`"
                            >
                                <div
                                    class="block-item"
                                    :style="`background-image: url(${$publicPath}/static/banner/home-n-1.jpg);`"
                                >
                                    <div class="inside">
                                        <h4>
                                            雄塑科技：关于部分董事及高级管理人员减持计划期限届满的公告关于部关于部分董事及高级管理人员减持计划期限届满的公告关于部
                                        </h4>
                                        <div class="date">2021-08-03</div>
                                        <div class="desc">
                                            <p>
                                                近期，广东雄塑科技集团股份有限公司（以下简称“雄塑科技”）工程中心成功通过了CNAS（中国合格评定国家认可近期，广东雄塑科技集团股份有限公司（以下简称“雄塑科技”）工程中心成功通过了CNAS…
                                            </p>
                                        </div>
                                        <div class="action">
                                            <a
                                                :to="{ name: 'NewsList' }"
                                                >了解更多<i
                                                    class="el-icon-arrow-right"
                                                ></i
                                            ></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="news-block__right news-block__child">
                                <img
                                    class="picture"
                                    :src="`${$publicPath}/static/banner/home-n-2.jpg`"
                                />
                                <div class="block-item">
                                    <div class="inside">
                                        <span class="sub-title">News</span>
                                        <h4>雄塑新闻</h4>
                                        <div class="desc">
                                            打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。
                                        </div>
                                        <div class="action">
                                            <router-link
                                                :to="{ name: 'NewsList' }"
                                                class="cb-primary"
                                                >了解更多</router-link
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="honor-list">
                        <div
                            class="honor-list__item"
                            v-for="(honor, index) in honorList"
                            :key="index"
                        >
                            <div class="left">
                                <img
                                    :src="`${$publicPath}/static/icon/${honor.icon}`"
                                    :alt="honor.name"
                                />
                            </div>
                            <div class="right">
                                <h4>{{ honor.name }}</h4>
                                <div class="desc">
                                    {{ honor.desc }}
                                </div>
                                <div class="action">
                                    <router-link :to="{ name: honor.link }">
                                        >了解更多<i
                                            class="el-icon-arrow-right"
                                        ></i
                                    ></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hd hd-product">
            <div class="ht">
                <div class="meta">
                    <i class="line"></i> <span class="text">PRODUCT</span>
                </div>
                <h2>雄塑产品</h2>
            </div>
            <div class="hc hc-product">
                <div class="hc-inside">
                    <div class="title">
                        <h3>绿色、环保、节能、节水</h3>
                    </div>
                    <div class="sub_title">ISO9001和ISO14001认证。</div>
                    <div class="desc">
                        获得了“农村建设重点推广产品”、“中国环境标志产品”、“新华节水认证产品”、“科技创新绿色节能产品”和“中国绿色环保建材产品”等荣誉。
                    </div>
                    <div class="action">
                        <router-link :to="{ name: 'Products' }"
                            >了解更多</router-link
                        ><i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
            <div class="hc hc-product__list">
                <div class="hc-inside">
                    <div class="list-block">
                        <div v-for="i in [1, 2, 3]" :key="i" class="list-item">
                            <img
                                :src="`${$publicPath}/static/products/p-p${i}.jpg`"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="list-desc">
                        <div class="title">
                            <h4>省级企业技术中心</h4>
                        </div>

                        <div class="desc">
                            公司拥有丰富的技术储备，对环保化学建材进行深入研究和持续创新，通过自主研发已经取得了110多项实用新型专利,参与了多项安装设计标准的编制，引领了产品应用技术的新潮流。
                        </div>
                        <div class="action">
                            <router-link :to="{ name: 'AboutUS' }"
                                >了解更多<i class="el-icon-arrow-right"></i
                            ></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hd hd-stock">
            <div class="hd-inside">
                <div class="ht">
                    <div class="meta">
                        <i class="line"></i>
                        <span class="text">INVESTORA</span>
                    </div>
                    <h2>投资者关系</h2>
                </div>

                <div class="hc">
                    <div class="equity-structure">
                        <div class="title">
                            <h3>股本结构</h3>
                            <router-link
                                :to="{ name: 'IRSecurities' }"
                                class="more"
                                >了解更多<v-icon
                                    class="co__action-icon"
                                    name="md-chevronright"
                                ></v-icon
                            ></router-link>
                        </div>
                        <div class="content">
                            <div class="stock-pic"></div>
                            <div class="stock-info">
                                <div class="stock-info__form">
                                    <div class="line line-1">
                                        <div class="h">总股本</div>
                                        <div class="m">3.04 亿股</div>
                                        <div class="r">100%</div>
                                    </div>
                                    <div class="line line-2">
                                        <div class="h">限售条件流通股</div>
                                        <div class="m">3.04 亿股</div>
                                        <div class="r">100%</div>
                                    </div>
                                    <div class="line line-3">
                                        <div class="h">无限售条件流通股</div>
                                        <div class="m">3.04 亿股</div>
                                        <div class="r">100%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="stock-movement">
                        <div class="title">
                            <h3>股票走势</h3>
                            <router-link
                                :to="{ name: 'IRSecurities' }"
                                class="more"
                                >了解更多
                                <v-icon
                                    class="co__action-icon"
                                    name="md-chevronright"
                                ></v-icon
                            ></router-link>
                        </div>
                        <div class="content">
                            <div class="stock-info">
                                <img
                                    src="http://pifm.eastmoney.com/EM_Finance2014PictureInterface/Index.aspx?ID=3005992&amp;UnitWidth=-6&amp;imageType=KXL&amp;EF=&amp;Formula=RSI&amp;AT=&amp;&amp;type=M&amp;token=44c9d251add88e27b65ed86506f6e5da&amp;_=0.008530832293599522"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="information-disclosure">
                        <div class="title">
                            <h3>信息披露</h3>
                            <router-link
                                :to="{ name: 'IRInfomation' }"
                                class="more"
                                >了解更多
                                <v-icon
                                    class="co__action-icon"
                                    name="md-chevronright"
                                ></v-icon
                            ></router-link>
                        </div>
                        <div class="content">
                            <div class="stock-info">
                                <div class="stock-info__announcement">
                                    <div class="announcement-item">
                                        <div class="text">
                                            <div class="mark">临时公告</div>
                                            <a href="https://www.xiongsu.cn/wp-content/uploads/2021/10/%E9%9B%84%E5%A1%91%E7%A7%91%E6%8A%80%EF%BC%9A2021%E5%B9%B4%E5%8D%8A%E5%B9%B4%E5%BA%A6%E6%9D%83%E7%9B%8A%E5%88%86%E6%B4%BE%E5%AE%9E%E6%96%BD%E5%85%AC%E5%91%8A.pdf">
                                                雄塑科技：2021年半年度权益分派实施公告
                                            </a>
                                        </div>
                                        <span class="date">2021-10-15</span>
                                    </div>
                                    <div class="announcement-item">
                                        <div class="text">
                                            <div class="mark">临时公告</div>
                                            <a href="https://www.xiongsu.cn/wp-content/uploads/2021/10/%E9%9B%84%E5%A1%91%E7%A7%91%E6%8A%80%EF%BC%9A%E5%85%B3%E4%BA%8E%E9%83%A8%E5%88%86%E8%91%A3%E4%BA%8B%E3%80%81%E8%91%A3%E4%BA%8B%E5%85%B3%E8%81%94%E4%BA%BA%E3%80%81%E7%9B%91%E4%BA%8B%E5%8F%8A%E7%A6%BB%E4%BB%BB%E9%AB%98%E7%BA%A7%E7%AE%A1%E7%90%86%E4%BA%BA%E5%91%98%E8%82%A1%E4%BB%BD%E5%87%8F%E6%8C%81%E8%AE%A1%E5%88%92%E6%97%B6%E9%97%B4%E8%BF%87%E5%8D%8A%E7%9A%84%E8%BF%9B%E5%B1%95%E5%85%AC%E5%91%8A.pdf">
                                                雄塑科技：关于部分董事、董事关联人、监事及离任高级管理人员股份减持计划时间过半的进展公告
                                            </a>
                                        </div>
                                        <span class="date">2021-10-09</span>
                                    </div>
                                    <div class="announcement-item">
                                        <div class="text">
                                            <div class="mark">临时公告</div>
                                            <a href="https://www.xiongsu.cn/wp-content/uploads/2021/10/%E9%9B%84%E5%A1%91%E7%A7%91%E6%8A%80%EF%BC%9A%E7%8B%AC%E7%AB%8B%E8%91%A3%E4%BA%8B%E5%85%B3%E4%BA%8E%E7%AC%AC%E4%B8%89%E5%B1%8A%E8%91%A3%E4%BA%8B%E4%BC%9A%E7%AC%AC%E5%8D%81%E5%85%AD%E6%AC%A1%E4%BC%9A%E8%AE%AE%E7%9B%B8%E5%85%B3%E4%BA%8B%E9%A1%B9%E7%9A%84%E7%8B%AC%E7%AB%8B%E6%84%8F%E8%A7%81.pdf">
                                                雄塑科技：独立董事关于第三届董事会第十六次会议相关事项的独立意见
                                            </a>
                                        </div>
                                        <span class="date">2021-09-40</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hd hd-join-us">
            <div class="hd-inside">
                <div class="ht">
                    <div class="meta">
                        <i class="line"></i>
                        <span class="text">JOIN US</span>
                    </div>
                    <h2>加入我们</h2>
                </div>

                <div class="hc">
                    <div class="switch-tabs">
                        <span
                            @click="stPanelShowOne = true"
                            :class="{ stActive: stPanelShowOne }"
                            class="st-1"
                            >招贤纳士</span
                        >
                        <span
                            @click="stPanelShowOne = false"
                            :class="{ stActive: !stPanelShowOne }"
                            class="st-2"
                            >雄塑学院</span
                        >
                    </div>

                    <div class="st-panel st-panel__1" v-show="stPanelShowOne">
                        <div class="join-us__list">
                            <div class="jobs-list">
                                <div
                                    :name="index + ''"
                                    v-for="(item, index) in jobsList"
                                    :key="index"
                                    class="jobs-list__item"
                                >
                                    <div class="jobs-list__item-header">
                                        <div class="jobs-list__item-bl">
                                            <div class="jobs-list__item-title">
                                                {{ item.title }}
                                            </div>
                                            <div class="jobs-list__item-salary">
                                                {{item.salary}}
                                            </div>
                                        </div>
                                        <div class="jobs-list__item-bl">
                                            <div class="jobs-list__item-exp">
                                                {{item.exp}}
                                            </div>
                                            <div class="jobs-list__item-pos">
                                                {{item.pos}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="join-us__info">
                            <h3>招聘说明</h3>

                            <div class="content">
                                广东雄塑科技集团股份有限公司是一家集研发、生产和销售塑料管道的高新技术企业。公司连续多年被评为“广东省制造业500强”，2015年荣膺行业
                                “企业五星品牌”，2017年1月成功上市。因公司发展需要，现诚聘优秀人才。
                            </div>

                            <h3>联系方式</h3>
                            <div class="content">
                                <img
                                    class="img-avatar"
                                    :src="`${$publicPath}/static/other/avatar-01.png`"
                                    alt="头像"
                                />
                                <br />
                                电话：0757-86518888
                                <br />
                                邮箱： homso@xiongsu.cn
                                <br />
                                地址：广东省佛山市南海区九江镇龙高路敦根路段雄塑工业园
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="st-panel st-panel__2" v-show="!stPanelShowOne">
                        <div class="one-content">
                            <div class="one-content__left">
                                <el-image
                                    class="co__img"
                                    fit="cover"
                                    :src="`${$publicPath}/static/banner/home-n-2.jpg`"
                                ></el-image>
                            </div>
                            <div class="one-content__right">
                                <div class="one-content__title">
                                    <h2>
                                        【雄塑科技】荣获国家实验室新版认可证书
                                    </h2>
                                </div>
                                <div class="one-content__date">2020-09-01</div>
                                <div class="one-content__desc">
                                    <p>
                                        近期，广东雄塑科技集团股份有限公司（以下简称“雄塑科技”）工程中心成功通过了CNAS中国合格评定国家认可委员会实验室认可换版监督评审和扩项评审，再次获得CNAS颁发的新版“国家实验室认可证书”。此次认可包含两个方面，一是认可准则ISO/IEC
                                        17025从2005版本更新为2017版，新认可准则要求更加严格、更加规范；二是检测标准的扩项，认可检测项目从原来的17项扩充到22项，同时新增11项判定标准。检测能力再上一个新台阶。
                                    </p>

                                    <p>
                                        雄塑科技工程中心自2019年5月首次获得CNAS国家实验室认可资质以来，秉承着对品质的不断追求，践行着“公正、科学、准确、满意”的质量方针，不断开拓创新，精益求精。此次雄塑科技工程中心再次通过改版后新标准的国家级（CNAS）实验室认可及扩项评审，表明了中国合格评定国家认可委员会对我司工程中心综合实力的充分肯定，彰显雄塑科技在产品研发和质量检验方面达到国际新高度，内部管理更加规范，同时也显示了公司品牌价值及产品市场竞争能力的进一步提升。
                                    </p>
                                </div>
                                <div class="action">
                                    <router-link :to="{ name: 'NewsDetail', params: { contentId: 3596 }}">了解更多<i
                                            class="el-icon-arrow-right"
                                        ></i
                                    ></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hd hd-cooperation">
            <div class="hd-inside">
                <div class="ht">
                    <div class="meta">
                        <i class="line"></i>
                        <span class="text">cooperation</span>
                    </div>
                    <h2>合作联系</h2>
                </div>

                <div class="hc">
                    <div class="cooperation-form">
                        <el-form
                            :model="contactForm"
                            ref="contactForm"
                            :rules="rules"
                        >
                            <div class="form-item">
                                <el-form-item
                                    class="form-item__left"
                                    prop="name"
                                >
                                    <label for="" class="text">* 姓名</label>
                                    <el-input
                                        class="form-item__input"
                                        placeholder="请输入您的姓名"
                                        v-model="contactForm.name"
                                        clearable
                                    >
                                    </el-input>
                                </el-form-item>
                                <el-form-item
                                    class="form-item__right"
                                    prop="phone"
                                >
                                    <label for="" class="text">* 电话</label>
                                    <el-input
                                        class="form-item__input"
                                        placeholder="请输入您的联系电话"
                                        v-model="contactForm.phone"
                                        clearable
                                    >
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="form-item">
                                <el-form-item
                                    class="form-item__full"
                                    prop="company"
                                >
                                    <label for="" class="text"
                                        >* 公司名称</label
                                    >
                                    <el-input
                                        class="form-item__input"
                                        placeholder="请输入您的公司名称"
                                        v-model="contactForm.company"
                                        clearable
                                    >
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="form-item">
                                <el-form-item
                                    class="form-item__full"
                                    prop="content"
                                >
                                    <label for="" class="text"
                                        >* 明确需求</label
                                    >
                                    <el-input
                                        class="form-item__input"
                                        placeholder="请填写您的需求信息"
                                        v-model="contactForm.content"
                                        type="textarea"
                                    >
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="form-item form-item__upload">
                                <div class="inside">
                                    <span class="el-icon-upload"></span>
                                    <span
                                        >点击上传文件（图片/PDF/Office
                                        文件）</span
                                    >
                                    <input
                                        class="el-upload"
                                        type="file"
                                        @change="getFiles($event)"
                                    />
                                </div>
                                <div
                                    class="select-file"
                                    v-show="uploadFileName"
                                >
                                    <el-alert
                                        :title="'已选择：' + uploadFileName"
                                        :closable="false"
                                        type="success"
                                    >
                                    </el-alert>
                                </div>
                            </div>
                            <div class="form-action">
                                <el-button
                                    type="primary"
                                    @click="onSubmit()"
                                    class="cb-primary"
                                    :loading="button_status.loading"
                                    >{{
                                        button_status.loading
                                            ? "加载中"
                                            : "联系我们"
                                    }}</el-button
                                >
                                <!-- <span @click="saveToCF()" class="cb-primary">联系我们</span> -->
                                <div class="info">
                                    我们将会在您提交信息后 24 小时内答复
                                </div>
                            </div>
                        </el-form>
                    </div>
                    <div class="cop-slogan">
                        <div class="text">
                            为员工创造机会，
                            为股东创造利润，为社会创造财富，实现振兴民族工业的伟大理想。
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@import "~@/assets/style/home.scss";
@import "~@/assets/style/home-mobile.scss";
</style>

<script>
// @ is an alias to /src
import HomeCarousel from "@/components/HomeCarousel.vue";
import cf7 from "@/api/contact-form-7";
// https://static.fun2ex.com/w1.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w2.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w3.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w4.jpg?imageMogr2/thumbnail/1920x

export default {
    name: "Home",
    components: {
        HomeCarousel,
    },
    data() {
        let ValidatePhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入您的联系号码"));
            } else {
                // 手机
                let isPhone =
                    /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/.test(
                        value
                    );

                // 固定电话
                let isTel = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/.test(value);

                if (isPhone || isTel) {
                    // 需要保存到后台的格式
                    // this.contactForm.contactType = isMail ? 2 : 1
                    callback();
                } else {
                    callback(
                        new Error("号码格式错误，请输入正确的手机或者座机号码")
                    );
                }
            }
        };
        return {
            stPanelShowOne: true,
            jobsList: [
                {
                    id: 1,
                    title: "董事长助理",
                    sub_title: "",
                    date: "2021-10-21",
                    salary: "20-30K",
                    pos: "广州佛山",
                    exp: "10年相关经验 / 本科 / 全职",
                    desc: `<p>招聘人数：1人</p>
<p>岗位薪资：年薪20-30万</p>
<p>岗位职责：</p>
<p>1、在董事长授权下，协助董事长进行营销团队的管理与客户开拓、商务谈判、对外接待等工作的开展。</p>
<p>2、主要负责开拓云南市场，建立良好的客户资源，提升塑料管道产品销售业绩。</p>
<p>3、收集和提供董事长所需的各种资讯，负责协助董事长制定公司的各项发展战略、管理规划、项目报告等事宜。</p>
<p>4、积极有效完成董事长交办的其它工作。</p>
<p>任职资格：</p>
<p>1、全日制本科学历，工商管理、企业管理、市场营销、金融类等专业优先，年龄28-40岁。</p>
<p>2、具有10年或以上建材行业销售管道工作经验，3年以上中大型管道制造业企业的销售副总/总监等相关工作经验。</p>
<p>3、良好的商务形象，思路清晰，抗压能力强，有较强的组织、协调、沟通、领导能力及人际交往能力，具有较强的决策、计划与执行能力。</p>
<p>4、踏实稳重、诚实敬业、品行端正，具有良好的职业道德操作，会讲粤语者优先考虑。</p>`,
                    url: "https://fun2ex.com",
                    image: "https://temp.im/800x514/007AFF/fff",
                    dark_model: false,
                },
                {
                    id: 2,
                    title: "管理会计经理",
                    sub_title: "",
                    date: "2021-10-21",
salary: "15-20K",
                    pos: "广州佛山",
                    exp: "3年相关经验 / 本科 / 全职",
                    desc: `<p>招聘人数：1人</p>
<p>岗位薪资：15K-20K</p>
<p>岗位职责：</p>
<p>1、协助财务总监制定财务中长期规划，组织本部门编制年度、季度、月度工作计划并组织实施。</p>
<p>2、完成管理会计部的各项工作、组织编制、汇总、分析、执行反馈、调整工作。</p>
<p>3、准确、及时提供各种报表，并整理、分析各种报表，提供改善意见等。</p>
<p>4、完善财务管理部，建立科学、系统符合企业实际情况的财务监控体系。</p>
<p>任职资格：</p>
<p>1、全日制本科及以上学历，财会、经济、金融等财务类专业。</p>
<p>2、具备大中型制造企业担任财务经理岗位2年及以上，3年以上主办会计工作经验，有合并报表经验，有上市工作经验优先。</p>
<p>3、精通国家财税法律法规，会计准则以及相关的财务、税务、审计法规、政策，有注册会计师证优先。</p>
<p>4、具备优秀的职业判断能力和丰富的财会项目分析处理经验。</p>
<p>5、具有全面的财务专业知识、账务处理、成本核算及财务管理经验。</p>`,
                    url: "https://fun2ex.com",
                    image: "https://temp.im/800x514/007AFF/fff",
                    dark_model: false,
                },
                {
                    id: 3,
                    title: "技术资料员",
                    sub_title: "",
                    date: "2021-10-21",
salary: "4.5-5.5K",
                    pos: "广州佛山",
                    exp: "3年相关经验 / 本科 / 全职",
                    desc: `<div class="wpb_wrapper">
			<p>招聘人数：1人</p>
<p>岗位薪资：4.5K-5.5K</p>
<p>岗位职责：</p>
<p>1、该岗位隶属于研发部门，负责技术资料档案的管理。</p>
<p>2、负责ERP系统资料的命名、编码，产品BOM表的编制录入。</p>
<p>3、负责专利的收集、专利技术文件的编写及申报工作。</p>
<p>4、负责与政府技术主管部门的日常沟通。</p>
<p>&nbsp;</p>
<p>任职资格：</p>
<p>1、本科学历，3年以上工作经验，有档案管理、技术资料管理或ERP系统管理经验。</p>
<p>2、具有档案管理的基础知识。</p>
<p>3、具有文案工作和较好的书写能力。</p>
<p>4、能熟练的掌握日常办公软件。</p>
<p>5、具有较强的学习能力和良好的沟通经验。</p>

		</div>`,
                    url: "https://fun2ex.com",
                    image: "https://temp.im/800x514/007AFF/fff",
                    dark_model: false,
                },
                {
                    id: 4,
                    title: "研发主任",
                    sub_title: "",
                    date: "2021-10-21",
salary: "12-15K",
                    pos: "广州佛山",
                    exp: "8年相关经验 / 本科 / 全职",
                    desc: `<div class="wpb_wrapper">
			<p>招聘人数：1人</p>
<p>岗位薪资：12K-15K</p>
<p>岗位职责：</p>
<p>1、协助制定公司中长期技术发展和新产品开发战略，编制年度研发工作计划。</p>
<p>2、组织实施研发项目的市场调查、评审及可行性研究。</p>
<p>3、负责组织研发项目的立项、实施和评审。</p>
<p>4、负责组织进行生产技术难题的攻克工作，负责中心人员的日常管理和资源的分配。</p>
<p>&nbsp;</p>
<p>任职资格：</p>
<p>1、具有扎实的高分子材料合成、加工的理论知识。</p>
<p>2、具有丰富的塑料管道制造行业工作经验和组织研发开发新项目的工作经验。</p>
<p>3、具有八年以上的同行业经验。</p>

		</div>`,
                    url: "https://fun2ex.com",
                    image: "https://temp.im/800x514/007AFF/fff",
                    dark_model: false,
                },
                
            ],
            honorList: [
                {
                    name: "集团介绍",
                    desc: "致力于成为国内管道行业的领导者，跻身全国管道行业前列。",
                    icon: "honor-icon-1.png",
                    link: "AboutUS",
                },
                {
                    name: "品牌介绍",
                    desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
                    icon: "honor-icon-2.png",
                    link: "AboutUS",
                },
                {
                    name: "生产基地",
                    desc: "拥有广东、广西、河南、江西、海南、云南六大生产基地。",
                    icon: "honor-icon-3.png",
                    link: "ProductionBase",
                },
                {
                    name: "集团荣誉",
                    desc: "获得了“中国环境标志产品”和“科技创新绿色节能产品”等荣誉。",
                    icon: "honor-icon-4.png",
                    link: "AboutUS",
                },
            ],
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入联系人姓名",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        validator: ValidatePhone,
                        trigger: "blur",
                    },
                ],
                company: [
                    {
                        required: true,
                        message: "请输入公司名称",
                        trigger: "blur",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "请填写您的地址",
                        trigger: "blur",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "请描述一下您的需求",
                        trigger: "blur",
                    },
                ],
            },
            contactForm: {
                type: this.$env == "pord" ? "4255" : "4129",
                name: "",
                phone: "",
                company: "",
                content: "",
                mail: "empty@system.com",
                province: "暂无", // 省
                county: "暂无", // 市/县
                district: "暂无", // 区
                attachment: "",
            },
            button_status: {
                loading: false,
            },
            uploadFileName: "", // 上传文件名
            
        };
    },
    methods: {
        async saveToCF() {
            let self = this;
            // 标志状态
            if (self.button_status.loading) return;

            self.button_status.loading = true;
            let rep = await cf7.SaveToCFfeedback(this.contactForm);
            self.button_status.loading = false;

            if (rep.data.status === "mail_sent") {
                // 提示成功
                self.$msgbox({
                    message:
                        "感谢您的投递，我们会在<br />获得信息第一时间与您联系",
                    dangerouslyUseHTMLString: true,
                    customClass: "cus-class__messagebox",
                    showConfirmButton: true,
                    confirmButtonText: "关闭",
                    closeOnClickModal: false,
                    center: true,
                    showClose: true,
                    callback: function () {
                        window.location.reload();
                    },
                });
            } else {
                let msg = rep.data.message;
                if (rep.data.invalid_fields.length) {
                    msg = `${msg} - ${rep.data.invalid_fields[0].message}`;
                }
                self.$message({
                    message: msg,
                    type: "warning",
                });
                // 提示失败
            }
        },
        locateToErr() {
            setTimeout(() => {
                const errorDiv = document.querySelectorAll(".is-error");
                errorDiv[0].scrollIntoView();
            }, 0);
        },
        getFiles(e) {
            console.log(e.target.files[0]);
            this.uploadFileName = e.target.files[0].name;
            this.contactForm.attachment = e.target.files[0];
        },
        onSubmit() {
                let self = this;
                // console.log("no name");
                self.$refs.contactForm.validate((valid) => {
                    if (valid) {
                        self.saveToCF();
                    } else {
                        self.locateToErr();
                        return false;
                    }
                    // if (valid) {
                    // // alert('submit!')
                    // self.saveContact(self.contactForm)
                    // } else {
                    // if (Object.entries(obj).length === 1 && obj.hasOwnProperty('agree')) {
                    //     self.$message({
                    //     message: '必须要确认隐私协议后方可继续',
                    //     type: 'warning'
                    //     })
                    // }
                    // return false
                    // }
                });
            },
    }
};
</script>
