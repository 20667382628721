<template>
    <div id="app" class="app" :class="'app-' + $route.meta.class">
        <Header />
        <router-view />
        <Footer />
    </div>
</template>

<script>
import Vue from "vue";
import { Icon, MessageBox, Message } from "element-ui";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import apiWpV2 from "@/api/wp";

Vue.use(Icon);

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;

export default {
    name: "main-app",
    components: {
        Header,
        Footer,
    },
    mounted() {
        //   初始化页面所有配置
        // this.getPageSetting()
        // this.getProductColor();
    },
    methods: {
        // getPageSetting
        async getPageSetting() {
            const response = await apiWpV2.getPageSetting({
                id: this.$env === "prod" ? "4217" : "4217",
            });
            return response.data;
        },
        // 初始化颜色配置
        // async getProductColor() {
        //     let color = {};
        //     const response = await apiWpV2.getProductColor();
        //     response.data.map((item) => {
        //         color[item.id] = {
        //             name: item.name,
        //             slug: item.slug,
        //             color: item.acf.color,
        //         };
        //     });
        //     return color;
        // },
    },
};
</script>

<style lang="scss">
@import "./assets/style/reset.css";
@import "./assets/style/common.scss";

#app {
    color: #333;
    //   min-width: 980px;
}

a {
    color: #333;
    text-decoration: none;

    &:hover {
        color: #0055a5;
        text-decoration: underline;
    }
}

.desktop {
    .app {
        min-width: 1040px;
    }
}
</style>
