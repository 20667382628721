<template>
    <div class="app-container top-line" :class="$route.meta.class">
        <div class="page-block">
            <div class="page-breadcrumb width-master">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{
                        $route.meta.title
                    }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <div
            class="team-introduction"
            :class="{ 'team-introduction__video': isShowVideo }"
        >
            <div class="intro-text" v-show="!isShowVideo">
                <div class="intro-text__title">About HOMSO</div>
                <div class="intro-text__subtitle">关于雄塑</div>
                <div class="intro-text__action">
                    <span class="cb-primary" @click="switchIntroVideoStatus()">
                        <i class="el-icon-video-play"></i> 播放宣传片
                    </span>
                </div>
            </div>

            <div class="intro-video" v-show="isShowVideo">
                <div
                    class="intro-video__block width-master"
                    id="intro-video-container"
                ></div>
            </div>

            <div
                class="intro-bottom width-master"
                :class="{ 'intro-bottom__video': isShowVideo }"
            >
                <div class="title">
                    <h3>
                        造塑胶精品，树国际品牌
                        <i class="icon el-icon-arrow-down"></i>
                    </h3>
                </div>
                <div class="content-detail">
                    <p>
                        广东雄塑科技集团股份有限公司位于千年古郡、商贸之都——佛山南海，其前身是广东雄塑科技实业有限公司，于2013年6月整体改制，集团现下设“广西雄塑科技发展有限公司、广东雄塑科技实业（江西）有限公司、河南雄塑实业有限公司”、“海南雄塑科技发展有限公司”、“云南雄塑科技发展有限公司”和“广东雄塑国际贸易有限公司”六家全资子公司，拥有广东南海、广西南宁、河南新乡、江西宜春、海南海口、云南玉溪六大生产基地，销售网点遍布全国，公司于2017年1月23日在深圳创业板上市。
                    </p>
                    <p>
                        “雄塑”品牌创立于1996年，是国内新型化学建材的知名品牌，2009年经国家工商总局认定为“中国驰名商标”，2015年1月荣膺首批“中国企业五星品牌”，公司工程中心于2019年5月8日获得国家实验室认可，公司于2019年5月30日荣获五星级售后服务认证证书。公司是“中国塑料加工工业协会副理事长单位”，公司早于2000年就通过了多项省部级科学技术成果鉴定，先后参与编制了多项塑料管道产品及安装设计标准，取得了100多项产品专利。
                    </p>
                    <br />
                    <p>
                        雄塑服务理念：客户至上，用户满意是宗旨。<br />
                        雄塑服务宗旨：质量第一，精益求精求发展，用户至上，顾客满意为宗旨。<br />
                        雄塑服务承诺：严格执行三包政策：包修、包换、包退，为容户提供理想的解决方案的服务承诺。<br />
                        1. 因产品质量问题引起的费用由雄塑公司承担。<br />
                        2. 由于运输、进场、安装等过程中造成的损坏，由提货、运输或施工单位负责。<br />
                        <br />
                        服务热线：<b>0757-81868241</b>(接受预约/咨询/报修/投诉/防伪查询)<br />
                        热线时间：周一至周五 (8: 00-12:00 13:30-17:30）<br />
                        服务邮箱：gdxs@xiongsu.cn
                    </p>
                </div>
            </div>
        </div>

        <div class="team-timeline">
            <div class="team-timeline__block width-master">
                <div class="team-timeline__title">
                    <h3>雄塑发展历程</h3>
                </div>
                <div class="team-timeline__swiper">
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide
                            v-for="list in xiongsuHistoryList"
                            :key="list.slug"
                        >
                            <div class="history-block">
                                <div class="year">{{ list.slug }}</div>
                                <div class="c1">{{ list.acf.title }}</div>
                                <div class="c2">{{ list.acf.sub_title }}</div>
                                <div class="m-line"></div>
                                <i class="icon el-icon-arrow-up"></i>
                                <div
                                    class="content-detail"
                                    v-html="list.acf.content"
                                ></div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-scrollbar" slot="scrollbar"></div>
                    </swiper>
                </div>
            </div>
        </div>
        <!-- ./team-timeline -->

        <div class="hd hd-team__block hd-development">
            <div class="hd-inside">
                <div class="ht">
                    <div class="meta">
                        <i class="line"></i>
                        <span class="text">Research And Development</span>
                    </div>
                    <h2>研发团队</h2>
                </div>
                <div class="hc">
                    <div class="research-top">
                        <img
                            :src="`${$publicPath}/static/other/research-top.jpg`"
                            alt=""
                        />
                    </div>
                    <div class="research-middle development-flex">
                        <div class="development-item development-item__left">
                            <div class="title">
                                <h4>Development</h4>
                            </div>
                            <div class="content">
                                <p>
                                    公司是高新技术企业，经批准组建了“省级企业技术中心”，同时成立了“佛山市高分子环保管道建材工程技术研究开发中心”。
                                </p>

                                <p>
                                    公司拥有丰富的技术储备，对环保化学建材进行深入研究和持续创新，通过自主研发已经取得了110多项实用新型专利，参与了多项安装设计标准的编制，引领了产品应用技术的新潮流。
                                </p>
                            </div>
                        </div>
                        <div class="development-item development-item__right">
                            <img
                                :src="`${$publicPath}/static/other/research-middle.jpg`"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="research-bottom development-flex">
                        <div class="development-item development-item__left">
                            <div class="title">
                                <h4>技术团队</h4>
                            </div>
                            <div class="content">
                                <p>
                                    公司自创立以来，一贯注重产品研发，属“高新技术企业”，拥有一批具有硕士和博士学历的高水平技术及管理人才，组建了“企业技术中心”和“工程技术研发中心”。
                                </p>
                            </div>
                        </div>
                        <div class="development-item development-item__right">
                            <div class="research-tech__info">
                                <img
                                    :src="`${$publicPath}/static/other/research-bottom_1.jpg`"
                                    alt=""
                                />
                                <div class="text">
                                    <div class="sub-title">
                                        Technical advantages
                                    </div>
                                    <div class="title">技术储备</div>
                                </div>
                            </div>
                            <div class="research-tech__info">
                                <img
                                    :src="`${$publicPath}/static/other/research-bottom_2.jpg`"
                                    alt=""
                                />
                                <div class="text">
                                    <div class="sub-title">
                                        Technical advantages
                                    </div>
                                    <div class="title">技术团队</div>
                                </div>
                            </div>
                            <div class="research-tech__info">
                                <img
                                    :src="`${$publicPath}/static/other/research-bottom_3.jpg`"
                                    alt=""
                                />
                                <div class="text">
                                    <div class="sub-title">
                                        Technical advantages
                                    </div>
                                    <div class="title">持续创新</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ./hd-development -->

        <div class="hd hd-team__block hd-group-honor width-master">
            <div class="hd-inside">
                <div class="ht">
                    <div class="meta">
                        <i class="line"></i>
                        <span class="text">Group Honor</span>
                    </div>
                    <h2>集团荣誉</h2>
                </div>
                <div class="hc">
                    <div class="hd-team__swiper-pc" v-show="!isMbile">
                        <div class="swiper-actions">
                            <div class="prev" slot="button-prev">
                                <span
                                    class="swiper-icon el-icon-arrow-left"
                                ></span>
                            </div>
                            {{ mySwiperHonorStatus.index }} /
                            {{ mySwiperHonorStatus.count }}
                            <div class="next" slot="button-next">
                                <span
                                    class="swiper-icon el-icon-arrow-right"
                                ></span>
                            </div>
                        </div>
                        <swiper
                            ref="mySwiperHonor"
                            :options="swiperOptionsHonor"
                            class="honor-swiper"
                            @slide-change="mySwiperHonorSlideChange"
                        >
                            <swiper-slide
                                v-for="honor in honorList"
                                :key="honor.id"
                            >
                                <div class="honor-block">
                                    <img
                                        :src="honor.acf.image"
                                        :alt="honor.title.rendered"
                                    />

                                    <div class="information">
                                        <div
                                            class="title"
                                            v-html="honor.title.rendered"
                                        ></div>
                                        <div
                                            class="desc"
                                            v-html="honor.acf.en_title"
                                        ></div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="hd-team__swiper-mbile" v-if="isMbile">
                        <div
                            class="honor-block__slide"
                            v-for="honor in honorList"
                            :key="honor.id"
                        >
                            <div class="honor-block">
                                <img
                                    :src="honor.acf.image"
                                    :alt="honor.title.rendered"
                                />

                                <div class="information">
                                    <div
                                        class="title"
                                        v-html="honor.title.rendered"
                                    ></div>
                                    <div
                                        class="desc"
                                        v-html="honor.acf.en_title"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem } from "element-ui";
import DPlayer from "dplayer";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import device from "current-device";
import apiWpV2 from "@/api/wp";

// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

Vue.use(Breadcrumb).use(BreadcrumbItem);

export default {
    name: "InvestorRelations",
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive,
    },
    data() {
        return {
            isShowVideo: false,
            list: [],
            dplayerObj: {},
            honorList: [
                {
                    id: 1,
                    title: {
                        rendered: "",
                    },
                    acf: {
                        image: "",
                        en_title: "",
                    },
                },
            ],
            xiongsuHistoryList: [],
            isMbile: device.mobile(),
            swiperOptions: {
                width: undefined,
                slidesPerView: 3,
                spaceBetween: 30,
                freeMode: false,
                scrollbar: {
                    el: ".swiper-scrollbar",
                    dragSize: 180,
                },
                // Some Swiper option/callback...
            },

            swiperOptionsHonor: {
                slidesPerView: 4,
                spaceBetween: 30,
                freeMode: false,
                navigation: {
                    nextEl: ".swiper-actions .next",
                    prevEl: ".swiper-actions .prev",
                },
            },
            mySwiperHonorStatus: {
                index: 1,
                count: 1,
            },
        };
    },
    beforeMount() {
        if (device.mobile()) {
            this.swiperOptions = {
                speed: 300,
                slidesPerView: "auto",
                freeMode: false,
                direction: "vertical",
                setWrapperSize: true,
                scrollbar: {
                    el: ".swiper-scrollbar",
                },
            };
            this.swiperOptionsHonor = {
                slidesPerView: 3,
                grid: {
                    rows: 2,
                },
                spaceBetween: 30,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            };
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        },
    },
    mounted() {
        this.init();
        this.mySwiperHonorSlideChange();
        // this.swiper.slideTo(3, 1000, false);
    },
    methods: {
        mySwiperHonorSlideChange() {
            this.mySwiperHonorStatus.index =
                this.$refs.mySwiperHonor.$swiper.activeIndex + 1;
            this.mySwiperHonorStatus.count =
                this.$refs.mySwiperHonor.$swiper.slides.length - 3;
        },
        setIntroVideo() {
            console.info("do-000");
            let dp_option = {
                container: document.getElementById("intro-video-container"),
                video: {
                    quality: [
                        {
                            name: "标清",
                            url: "https://vod.fun2ex.com/4cd0b591vodtransgzp1251632949/8cefabfb387702303899823725/v.f100020.mp4",
                            type: "normal",
                        },
                        {
                            name: "高清",
                            url: "https://vod.fun2ex.com/4cd0b591vodtransgzp1251632949/8cefabfb387702303899823725/v.f100030.mp4",
                            type: "normal",
                        },
                        {
                            name: "超清",
                            url: "https://vod.fun2ex.com/4cd0b591vodtransgzp1251632949/8cefabfb387702303899823725/v.f100040.mp4",
                            type: "normal",
                        },
                    ],
                    defaultQuality: 0,
                },
            };
            // init dplayerObj
            this.dplayerObj = new DPlayer(dp_option);

            this.dplayerObj.on("ended", () => {
                this.isShowVideo = false;
            });
        },
        switchIntroVideoStatus() {
            this.isShowVideo = !this.isShowVideo;
            if (this.isShowVideo) {
                this.dplayerObj.play();
            } else {
                this.dplayerObj.pause();
            }
        },
        async getXiongsuHistory(config) {
            const res = await apiWpV2.getXiongsuHistory(config);
            this.xiongsuHistoryList = res.data;
        },
        // getGroupHonor
        async getGroupHonor(config) {
            const res = await apiWpV2.getGroupHonor(config);
            this.honorList = res.data;
        },
        init() {
            this.getXiongsuHistory({
                per_page: 100,
            });
            this.getGroupHonor({
                per_page: 100,
            });
            this.setIntroVideo();
        },
    },
};
</script>
<style lang="scss">
.team-introduction {
    height: 900px;
    background: url(~@/assets/image/page-about-team.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    &__video {
        height: 1050px;

        &:after {
            display: none;
        }
    }

    &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.0001) 2.01%,
            rgba(0, 0, 0, 0.8) 100%
        );
        content: " ";
        top: 0;
        left: 0;
    }

    .intro-text {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 4;
        transform: translate(-50%, -120%);

        &__title {
            font-size: 60px;
            padding-bottom: 10px;
            font-weight: bold;
        }

        &__subtitle {
            padding-bottom: 45px;
            font-size: 28px;
        }

        .cb-primary {
            padding: 15px 60px;
            cursor: pointer;
        }
    }

    .intro-video {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.8);

        &__block {
            height: 855px;
            background: #f00;
        }
    }

    .intro-bottom {
        position: absolute;
        left: 50%;
        bottom: 0;
        z-index: 2;
        transform: translate(-50%, 0);
        z-index: 3;
        color: #fff;
        margin: 60px 0;

        &__video {
            height: 10%;
            overflow: auto;
            margin-top: 0;
        }

        .title {
            font-size: 24px;
            padding-bottom: 30px;
            cursor: pointer;

            .icon {
                border: 1px solid #fff;
                color: #fff;
                border-radius: 100px;
                padding: 2px;
                font-size: 16px;
                margin-left: 15px;
            }

            h3 {
                display: flex;
                align-items: center;
            }
        }
        .content-detail {
            position: relative;
            font-size: 14px;
            line-height: 1.8;

            &__hide {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                height: 5.4em;
            }
        }
    }
}

.team-timeline {
    height: 1010px;
    background: url(~@/assets/image/bg-timeline.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;

    &__title {
        padding: 60px 0 40px;
        font-size: 24px;
    }

    .swiper-wrapper {
        height: 803px;
    }

    .swiper-slide {
        height: 703px;
        width: 460px;
        color: #222;
        font-family: PingFangSC, "微软雅黑";

        // &:nth-child(odd) {
        //     background: rgba(0, 0, 0, 0.8)
        // }
        // &:nth-child(even) {
        //     background: rgba(0, 255, 0, 0.5);
        // }

        .history-block {
            box-sizing: border-box;
            overflow-y: auto;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            // width: 80%;
            // margin: 0 auto;
            margin: 80px 28px;
            height: 543px;
            .year {
                font-size: 60px;
                padding-bottom: 10px;
            }
            .c1 {
                font-size: 28px;
                padding-bottom: 10px;
            }
            .c2 {
                font-size: 18px;
                padding-bottom: 28px;
            }

            .m-line {
                background: #222;
                height: 4px;
                margin-bottom: 20px;
            }
            .icon {
                padding: 3px;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                border: 1px solid #222;
                border-radius: 100px;
            }
            .content-box {
                padding-top: 40px;
                line-height: 1.8;
                display: block;
                .date {
                    font-size: 28px;
                    padding-bottom: 14px;
                }
            }
        }

        @media (any-hover: hover) {
            &:hover {
                color: #fff;
                cursor: pointer;
                background: url(~@/assets/image/timeline-bg__active@2x.png)
                    no-repeat;
                background-size: 100% 100%;
                background-position: center center;

                .history-block {
                    .icon {
                        border-color: #fff;
                    }
                    .content-box {
                        display: block;
                    }
                }

                .m-line {
                    background: #fff;
                }
            }
        }
    }
}

.hd-team {
    &__block {
        .ht {
            padding-top: 100px;
            padding-bottom: 30px;
        }
    }
}

.hd-development {
    .development-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .research-top {
        img {
            display: block;
            width: 100%;
        }
    }

    .research-middle {
        .development-item__right {
            img {
                display: block;
                height: 480px;
                width: auto;
            }
        }

        .development-item__left {
            padding-left: 100px;
            padding-right: 140px;
        }

        .title {
            font-size: 60px;
            padding: 65px 0;
        }
        .content {
            line-height: 1.8;
            p {
                padding: 10px 0;
            }
        }
    }
    .research-bottom {
        padding-top: 30px;
        .development-item__left {
            padding-left: 100px;
            padding-top: 90px;
            padding-right: 150px;
            width: 330px;
            flex: 1;

            .title {
                padding-bottom: 24px;
                font-size: 28px;
                h4 {
                    background: url(~@/assets/image/icon-research.png) no-repeat;
                    background-size: auto 30px;
                    background-position: left center;
                    padding-left: 45px;
                }
            }

            .content {
                line-height: 1.8;
                p {
                    padding: 10px 0;
                }
            }
        }
        .development-item__right {
            width: 942px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                display: block;
                height: auto;
                width: 300px;
            }
        }
    }

    .research-tech__info {
        position: relative;
        .text {
            position: absolute;
            bottom: 0;
            padding: 30px 24px;
            color: #fff;

            .sub-title {
                font-size: 14px;
                font-weight: 100;
            }
            .title {
                font-size: 24px;
                padding-top: 10px;
            }
        }
    }
}

.hd-group-honor {
    padding-bottom: 50px;
    .hc {
        position: relative;
    }
    .swiper-actions {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        top: -70px;
        width: 100%;
        left: 0;

        .prev {
            padding-right: 20px;
        }
        .prev,
        .next {
            padding-left: 20px;
            span {
                border: 1px solid #ccc;
                color: #ccc;
                border-radius: 100px;
                font-size: 18px;
                padding: 8px;
                cursor: pointer;
                &:hover {
                    border-color: #999;
                    color: #999;
                }
            }
        }
    }
    .honor-block {
        text-align: center;
        width: 360px;
        img {
            width: 100%;
            height: auto;
            display: block;
        }

        .title {
            padding-top: 20px;
            font-size: 20px;
            font-weight: bold;
        }

        .desc {
            font-size: 14px;
            font-weight: 100;
            padding-top: 10px;
        }
    }
}

.mobile {
    .team-introduction {
        height: 50vw;
        margin-bottom: 50vw;
        &__video {
            height: 56.2vw;
        }
        .intro-text {
            &__title {
                font-size: 16px;
                padding-bottom: 5px;
            }
            &__subtitle {
                font-size: 14px;
                padding-bottom: 10px;
            }

            transform: translate(-50%, -50%);

            .cb-primary {
                padding: 2vw 5vw;
            }
        }
        .intro-video {
            height: 56.2vw;
            &__block {
                height: 56.2vw;
            }
        }
        .intro-bottom {
            padding-top: 50vw;
            margin: 0 auto;
            width: 90vw;
            height: 50vw;
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0, 0);
            color: #333;
            &__video {
                padding-top: 0;
            }
            .title {
                padding-top: 15px;
                font-size: 14px;
                padding-bottom: 15px;

                .icon {
                    display: none;
                    color: #333;
                    border-color: #333;
                    font-size: 10px;
                }
            }
            .content-detail {
                font-size: 10px;
                line-height: 1.6;
                height: 30vw;
                overflow-y: auto;
            }
        }
    }
    .team-timeline {
        height: 135vw;
        &__title {
            font-size: 14px;
            width: 90vw;
            margin: 0 auto;
            padding: 5vw 0 2vw;
        }
        &__swiper {
        }
        .swiper-container {
            height: 120vw;
        }
        .swiper-slide {
            width: 90vw;
            margin: 0 auto;
            height: auto;

            .history-block {
                margin: 5vw 0vw;
                height: auto;

                .year {
                    font-size: 18px;
                    font-weight: bold;
                    padding-bottom: 5px;
                }
                .c1 {
                    font-size: 12px;
                    padding-bottom: 5px;
                }
                .c2 {
                    font-size: 12px;
                    padding-bottom: 5px;
                }
                .m-line {
                    height: 2px;
                }
                .icon {
                    display: none;
                }

                .content-box {
                    padding-top: 5px;
                    font-size: 12px;
                    line-height: 1.6;
                    display: block;
                    .date {
                        font-size: 10px;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }
    .hd-development {
        .ht {
            padding: 6vw 5vw 5vw 5vw;
        }

        .research-top {
            width: 100vw;
            height: 45vw;

            img {
                object-fit: cover;
                width: 100vw;
                height: 45vw;
            }
        }
        .research-middle {
            background: #fff;
            .title {
                font-size: 24px;
                padding: 0;
            }
            .content {
                font-size: 11px;
                color: #666;
            }
            .development-item {
                &__left {
                    padding: 0;
                    width: 70vw;
                    box-sizing: border-box;
                    padding: 0 5vw;
                }
                &__right {
                    width: 30vw;
                    img {
                        object-fit: cover;
                        width: 100vw;
                        height: 90vw;
                    }
                }
            }
        }
        .research-bottom {
            display: block;
            padding-top: 5vw;

            .development-item {
                &__left {
                    padding: 0;
                    width: 90vw;
                    margin: 0 auto;

                    .title {
                        font-size: 14px;
                        padding-bottom: 0vw;
                        h4 {
                            background-size: auto 90%;
                            padding-left: 8vw;
                        }
                    }

                    .content {
                        font-size: 11px;
                        color: #666;
                    }
                }
                &__right {
                    width: 100vw;

                    img {
                        width: 33.33vw;
                        object-fit: cover;
                        height: 70vw;
                    }

                    .research-tech__info {
                        .text {
                            padding: 5vw;
                            .sub-title {
                                font-size: 10px;
                            }
                            .title {
                                padding-top: 2vw;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .hd-group-honor {
        background: #fff;
        padding-bottom: 5vw;
        .ht {
            padding: 6vw 5vw 5vw 5vw;
        }

        .hd-team__swiper-mbile {
            display: flex;
            flex-wrap: wrap;
            width: 90vw;
            margin: 0 auto;
            justify-content: space-between;

            .honor-block__slide {
                width: 42.5vw;
            }
            .honor-block {
                width: 42.5vw;
                padding-bottom: 5vw;

                .title {
                    padding-top: 3vw;
                    font-size: 12px;
                }
                .desc {
                    padding-top: 1vw;
                    font-size: 8px;
                }
            }
        }
    }
}
</style>
