import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import initGlobalFilter from "./filters";
import "./libs/rem.js";

// 外部图标库
import OhVueIcon from "oh-vue-icons";
import {
    BiTelephone,
    FaFacebookSquare,
    FaTwitter,
    FaWeibo,
    IoLogoWechat,
    FaLinkedinIn,
    BiChatDots,
    CoArrowThickToTop,
    BiArrowRightCircle,
    BiArrowLeftCircle,
    PrAngleRight,
    PrAngleDown,
    CoLocationPin,
    HiMail,
    BiArrowLeftCircleFill,
    BiArrowRightCircleFill,
    MdChevronright,
    MdMenu,
} from "oh-vue-icons/icons";
OhVueIcon.add(
    BiTelephone,
    FaFacebookSquare,
    FaTwitter,
    FaWeibo,
    IoLogoWechat,
    FaLinkedinIn,
    BiChatDots,
    CoArrowThickToTop,
    BiArrowRightCircle,
    BiArrowLeftCircle,
    PrAngleRight,
    PrAngleDown,
    CoLocationPin,
    HiMail,
    BiArrowLeftCircleFill,
    BiArrowRightCircleFill,
    MdChevronright,
    MdMenu
);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === "production") {
//     const { mockXHR } = require("../mock");
//     mockXHR();
// }

Vue.component("v-icon", OhVueIcon);

Vue.config.productionTip = false;
Vue.prototype.$env = "prod";
// 全局变量
Vue.prototype.$global = {
    // 颜色配置，从配置文件中读取
    color: {}
};
let publicStaticPath = "https://xiongsu-assets.fun2ex.com/prd";
let host = window.location.host;
if (host === "www.xiongsu.cn" || host === "xiongsu.cn") {
    publicStaticPath = "https://xiongsu-assets.fun2ex.com/prd";
    Vue.prototype.$env = "prod";
} else if (host === "uat.xiongsu.cn") {
    publicStaticPath = "https://xiongsu-assets.fun2ex.com/uat";
    Vue.prototype.$env = "uat";
} else {
    publicStaticPath = "";
    Vue.prototype.$env = "dev";
}
Vue.prototype.$publicPath = publicStaticPath;

// register global utility filters
initGlobalFilter(Vue);

new Vue({
    router,
    // store,
    render: (h) => h(App),
}).$mount("#app");
