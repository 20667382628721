/** ***** number操作相关工具函数 *******/

/**
 * 用符号（默认为逗号）格式化金钱
 *
 * @param {string} val
 * @param {string} symbol 默认`,`
 * @returns {string|*|XML|void}
 * @example
 *
 * formatMoney('1234567890');
 * // => 1,234,567,890
 */
export function formatMoney (val, symbol) {
  /* eslint-disable-next-line */
  if (symbol === void 0) symbol = ','
  return val.replace(/\B(?=(\d{3})+(?!\d))/g, symbol)
}

/**
 * 为数字加上单位：万或亿
 *
 * @param {number} number 输入数字.
 * @param {number} decimalDigit 小数点后最多位数，默认为2
 * @return {*} 加上单位后的数字
 * @example
 *
 * addChineseUnit(1000.01)
 * // => 1000.01
 *
 * addChineseUnit(10000)
 * // => 1万
 *
 * addChineseUnit(99000)
 * // => 9.9万
 *
 * addChineseUnit(566000)
 * // => 56.6万
 *
 * addChineseUnit(5660000)
 * // => 566万
 *
 * addChineseUnit(44440000)
 * // => 4444万
 *
 * addChineseUnit(11111000)
 * // => 1111.1万
 *
 * addChineseUnit(444400000)
 * // => 4.44亿
 *
 * addChineseUnit(400000000000000000000000)
 * // => 4000万亿亿
 *
 * addChineseUnit(4000000000000000000000000)
 * // => 4亿亿亿
 */

function getDigit (integer) {
  var digit = -1
  while (integer >= 1) {
    digit++
    integer = integer / 10
  }
  return digit
}

function addWan (integer, number, mutiple, decimalDigit) {
  var digit = getDigit(integer)
  if (digit > 3) {
    var remainder = digit % 8
    // ‘十万’、‘百万’、‘千万’显示为‘万’
    if (remainder >= 5) {
      remainder = 4
    }
    return Math.floor(number / Math.pow(10, remainder + mutiple - decimalDigit)) / Math.pow(10, decimalDigit) + '万'
  } else {
    return Math.floor(number / Math.pow(10, mutiple - decimalDigit)) / Math.pow(10, decimalDigit)
  }
}

export function addChineseUnit (number, decimalDigit) {
  /* eslint-disable-next-line */
  if (decimalDigit === void 0) decimalDigit = 2
  var isNeedAbs = false
  if (number < 0) {
    isNeedAbs = true
    number = Math.abs(number)
  }
  var integer = Math.floor(number)
  var digit = getDigit(integer)
  // ['个', '十', '百', '千', '万', '十万', '百万', '千万'];
  var unit = []
  if (digit > 3) {
    var multiple = Math.floor(digit / 8)
    if (multiple >= 1) {
      var tmp = Math.floor(integer / Math.pow(10, 8 * multiple))
      unit.push(addWan(tmp, number, 8 * multiple, decimalDigit))
      for (var i = 0; i < multiple; i++) {
        unit.push('亿')
      }
      if (isNeedAbs) {
        return '-' + unit.join('')
      } else {
        return unit.join('')
      }
    } else {
      if (isNeedAbs) {
        return '-' + addWan(integer, number, 0, decimalDigit)
      } else {
        return addWan(integer, number, 0, decimalDigit)
      }
    }
  } else {
    if (isNeedAbs) {
      return '-' + number
    } else {
      return number
    }
  }
}

/**
 * 格式化数字、金额、千分位、保留几位小数、舍入舍去
 *
 * @since 1.0.7
 * @param number 要格式化的数字
 * @param decimals 保留几位小数
 * @param decPoint 小数点符号
 * @param thousandsSep 千分位符号
 * @param roundTag 舍入参数，默认 'ceil' 向上取,'floor'向下取,'round' 四舍五入
 * @returns {XML|void|*|string}
 * @example
 *
 * formatNumber(2, 2, '.', ',');
 * // => 2.00
 */
export function formatNumber (number, decimals, decPoint, thousandsSep, roundTag) {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  roundTag = roundTag || 'ceil' // 'ceil','floor','round'
  var n = !isFinite(+number) ? 0 : +number
  var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
  var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
  var re = /(-?\d+)(\d{3})/
  var s = ''
  var toFixedFix = function (n, prec) {
    var k = Math.pow(10, prec)
    return '' + parseFloat(Math[roundTag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + sep + '$2')
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}
