<template>
    <div class="ir-navigation">
        <div v-for="nav in navigation" :key="nav.name" class="navigation-item">
            <router-link :to="{ name: nav.name }">{{ nav.text }}</router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: "InvestorRelationsNav",
    data() {
        return {
            navigation: [
                {
                    text: "证券概括",
                    name: "IRSecurities",
                },
                {
                    text: "公司治理",
                    name: "IRCompany",
                },
                {
                    text: "股东回报",
                    name: "IRStockholder",
                },
                {
                    text: "信息披露",
                    name: "IRInfomation",
                },
                {
                    text: "投资者互动",
                    name: "IRInteractive",
                },
                {
                    text: "投资者保护",
                    name: "IRProtect",
                },
            ],
        };
    },
};
</script>
<style lang="scss" scoped>
.ir-navigation {
    display: flex;
    .navigation-item {
        margin-bottom: -1px;
        a {
            display: block;
            height: 88px;
            line-height: 88px;
            padding: 0 30px;

            &:hover {
                text-decoration: none;
            }
        }

        .router-link-active {
            color: #fff;
            background: #333333;
        }
    }
}

.mobile {
    .ir-navigation {
        padding-top: 5vw;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
        align-items: flex-start;
        // flex-wrap: wrap;
        align-content: start;

        &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }

        .navigation-item {
            margin-bottom: 0px;
            a {
                display: block;
                height: auto;
                line-height: 1;
                padding: 10px 0;
                text-align: center;
                width: 25vw;
                font-size: 12px;

                &:hover {
                    text-decoration: none;
                }
            }

            .router-link-active {
                color: #fff;
                background: #333333;
            }
        }
    }
}
</style>
