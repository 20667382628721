import device from "current-device";

console.log(device.desktop());

// 设置 rem 函数
function setRem() {
  // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
  let htmlWidth =
    document.documentElement.clientWidth || document.body.clientWidth;

  //得到html的Dom元素
  let htmlDom = document.getElementsByTagName("html")[0];

  //设置根元素字体大小
  let fontSize = 16;

  if (device.desktop()) {
    // PC
    fontSize = htmlWidth > 1550 ? 16 : 14;

    if (htmlWidth < 1200) {
      fontSize = 12;
    }
  } else {
    // Mobile
      fontSize = htmlWidth / 20;
      console.log(fontSize, htmlWidth);
  }

  htmlDom.style.fontSize = fontSize + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};
