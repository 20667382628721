<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/${$route.meta.class}.jpg?v=20220816);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block width-master">
            <div class="page-breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{
                        $route.meta.title
                    }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <!-- <div class="top-line"></div> -->

        <!-- <div
            class="products-list"
            v-for="(product, pIndex) in products"
            :key="pIndex"
        >
            <div class="product-title width-master">{{ product.name }}</div>
            <div class="product-hd">
                <div
                    class="product-model__b"
                    v-for="(item, index) in product.data"
                    :key="index"
                    :class="'product-model__item-' + (index % 4)"
                    :style="`background-image: url(${$publicPath}/static/products/${item.image});`"
                >
                    <div class="content">
                        <h3>{{ item.name }}</h3>
                        <div class="action">
                            <router-link
                                :to="{
                                    name: 'ProductsDetail',
                                    params: { productId: 123 },
                                }"
                                >了解更多</router-link
                            ><v-icon
                                class="co__action-icon"
                                name="md-chevronright"
                            ></v-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div
            class="products-list"
            v-for="(pMap, pIndex) in productMap"
            :key="pMap.id + pIndex"
        >
            <div class="product-title width-master">{{ pMap.name }}</div>
            <div class="product-hd" v-if="pMap.slug != 'sp'">
                <div
                    class="product-model__b"
                    v-for="(item, index) in productData[pMap.id]"
                    :key="item.id"
                    :class="'product-model__item-' + (index % 4)"
                    :style="`background-image: url(${item.acf.image});`"
                >
                    <div class="content">
                        <h3>{{ item.acf.title }}</h3>
                        <div class="action">
                            <router-link
                                :to="{
                                    name: 'ProductsDetail',
                                    params: {
                                        productCategoryId: item.acf.category[0],
                                        postId: item.acf.post_id,
                                    },
                                }"
                                :title="pMap.pds.name"
                                >了解更多</router-link
                            ><v-icon
                                class="co__action-icon"
                                name="md-chevronright"
                            ></v-icon>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 英雄系列 -->
            <div class="product-hd" v-if="pMap.slug == 'sp'">
                <div
                    class="product-model__a"
                    v-for="item in productData[pMap.id]"
                    :key="item.id"
                    :style="`background-image: url(${item.acf.image});`"
                >
                    <div class="content">
                        <h3>{{ item.title.rendered }}</h3>
                        <div class="sub_h3">{{ item.acf.title }}</div>
                        <div class="action">
                            <router-link
                                :to="{
                                    name: 'ProductsDetail',
                                    params: {
                                        productCategoryId: item.acf.category[0],
                                        postId: item.acf.post_id,
                                    },
                                }"
                                :title="pMap.pds.name"
                                >了解更多</router-link
                            ><v-icon
                                class="co__action-icon"
                                name="md-chevronright"
                            ></v-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import apiWpV2 from "@/api/wp";

export default {
    name: "ProductsIndex",
    data() {
        return {
            name: "ProductsIndex",
            productMap: {},
            productMapLength: 0,
            productData: {},
            products: [
                {
                    name: "PVC",
                    data: [
                        {
                            name: "PVC-U环保排水管",
                            image: "pvc-u-1@2x.jpg",
                            link: "",
                        },
                        {
                            name: "PVC-U环保排水管",
                            image: "pvc-u-2@2x.jpg",
                            link: "",
                        },
                        {
                            name: "PVC精品家装线管、PE-RT采暖管",
                            image: "pvc-u-3@2x.jpg",
                            link: "",
                        },
                        {
                            name: "PVC难燃电线槽管",
                            image: "pvc-u-4@2x.jpg",
                            link: "",
                        },
                    ],
                },
                {
                    name: "PR-R",
                    data: [
                        {
                            name: "PP-R精品家装管",
                            image: "pp-r-1@2x.jpg",
                            link: "",
                        },
                        {
                            name: "PP-R环保健康饮用水管",
                            image: "pp-r-2@2x.jpg",
                            link: "",
                        },
                    ],
                },
                {
                    name: "PE",
                    data: [
                        {
                            name: "PE燃气管道",
                            image: "pe-1@2x.jpg",
                            link: "",
                        },
                        {
                            name: "聚乙烯（PE）给水管",
                            image: "pe-2@2x.jpg",
                            link: "",
                        },
                    ],
                },
                {
                    name: "其他",
                    data: [
                        {
                            name: "克拉管",
                            image: "p-o-1@2x.jpg",
                            link: "",
                        },
                        {
                            name: "缠绕管与波纹管",
                            image: "p-o-2@2x.jpg",
                            link: "",
                        },
                        {
                            name: "铝塑-卫生饮用水管",
                            image: "p-o-3@2x.jpg",
                            link: "",
                        },
                        {
                            name: "通信、电力管",
                            image: "p-o-4@2x.jpg",
                            link: "",
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async getProdactsPage(config) {
            const res = await apiWpV2.getProductIndex(config);
            this.setProductData(res.data);
            console.info(this.productData);
        },
        async getProductCategory() {
            const res = await apiWpV2.getProductCategory();
            // console.log(res.data.id, res.data.name);
            this.setProductCategory(res.data);
        },
        setProductData(resData) {
            let self = this;
            resData.map((value) => {
                // console.log(value)
                // 判断 self.productData 是否已经存在 value.acf.category[0] key
                if (self.productData[value.acf.category[0]]) {
                    // 如果已经存在，则直接 push value
                    self.productData[value.acf.category[0]].push(value);
                } else {
                    // 如果不存在，则创建一个新的数组，并 push value
                    self.$set(self.productData, value.acf.category[0], []);
                    self.productData[value.acf.category[0]].push(value);
                }
            });
        },
        setProductCategory(resData) {
            let self = this;
            // 限定从 0 开始
            console.log(resData);
            self.productMapLength = resData.length;
            // 初始化，根据权重重新排列数组
            resData.map((value) => {
                console.log(value);
                self.$set(self.productMap, value.acf.weight, {
                    id: value.id,
                    name: value.name,
                    slug: value.slug,
                    description: value.description,
                    taxonomy: value.taxonomy,
                    pds: value.acf.product_detail_setting || {},
                });
            });

            // console.info(self.productMap);
        },
        init() {
            // 获取产品页面
            this.getProdactsPage({
                per_page: 99,
                page: 1,
            });
            // 获取产品分类
            this.getProductCategory({
                per_page: 99,
                page: 1,
            });
        },
    },
};
</script>
<style lang="scss"></style>
