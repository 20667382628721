import {
  formatDate,
  formatTimeAgo,
  timeLeft,
  formatRemainTime,
  monthDays
} from '@/utils/time'

import {
  formatMoney,
  formatNumber
} from '@/utils/number'

const filters = {
  formatMoney,
  formatNumber,
  timeLeft,
  formatRemainTime,
  monthDays,
  formatDate,
  formatTimeAgo,
  fixed2 (num) {
    return Number(num).toFixed(2)
  },
  sortDate  (str) {
    // console.info(str)
    const newStr = str.match(/\d*-\d*-\d*/)[0]
    const date = newStr || str
    return date.replace(/-/gi, '.')
  },
  sliceToEllipsis (str, limit) {
    if (!str || str.length <= limit) {
      return str
    }
    return str.slice(0, limit) + '...'
  }
}

export default Vue => {
  for (const name in filters) {
    const filter = filters[name]
    Vue.filter(name, filter)
  }
}
