<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/${$route.meta.class}.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block width-master">
            <div class="page-breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{
                        $route.meta.title
                    }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="news-block__carousel">
                <CarouselOne :posts-data="stickyPosts"></CarouselOne>
            </div>

            <div class="news-block__list">
                <div class="list-model__a">
                    <div
                        v-for="(item, index) in list"
                        :key="item.id"
                        class="list-model__item"
                        :class="'list-model__item-' + (index % 4)"
                    >
                        <div class="co-left">
                            <el-image
                                class="co__img"
                                fit="cover"
                                :src="item.thumb_image"
                            ></el-image>
                        </div>
                        <div class="co-right">
                            <div class="co__action">
                                <router-link
                                    :to="{
                                        name: 'NewsDetail',
                                        params: { 
                                            contentId: item.id
                                        },
                                    }"
                                    >查看详情</router-link
                                ><v-icon
                                    class="co__action-icon"
                                    name="md-chevronright"
                                ></v-icon>
                            </div>
                            <div class="co__title">
                                <router-link
                                    :to="{
                                        name: 'NewsDetail',
                                        params: { contentId: item.id },
                                    }"
                                    ><h2>
                                        {{ item.title.rendered }}
                                    </h2></router-link
                                >
                            </div>
                            <div class="co__date">
                                {{ item.date | formatDate("yyyy-MM-dd") }}
                            </div>
                            <div
                                class="co__desc"
                                v-html="item.excerpt.rendered"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="news-block__load-more">
                <span
                    v-show="loadMoreStatus"
                    class="cb-primary"
                    v-on:click="loadMore"
                    >加载更多</span
                >
                <span v-show="!loadMoreStatus" class="no-more"
                    >没有更多了~</span
                >
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem, Image } from "element-ui";
import CarouselOne from "@/components/CarouselOne.vue";
import apiWpV2 from "@/api/wp";
Vue.use(Breadcrumb).use(BreadcrumbItem).use(Image);

export default {
    name: "NewsList",
    components: {
        CarouselOne,
    },
    mounted() {
        // 根据路由名称加载不同资源
        this.setCidAndInitLoad(this.$route.name);
    },
    watch: {
        $route(to) {
            // 根据路由名称加载不同资源
            this.setCidAndInitLoad(to.name);
        },
    },
    data() {
        return {
            name: "NewsList",
            listDefault: [
                {
                    id: 0,
                    title: {
                        rendered: "",
                    },
                    date: "",
                    excerpt: {
                        rendered: "",
                    },
                    thumb_image: "",
                },
            ],
            list: [],
            stickyPosts: [],
            cid: 0,
            pagination: {
                currentPage: 0,
                pageSize: 10,
                total: 0,
                totalPages: 100,
            },
            loadMoreStatus: true,
        };
    },
    methods: {
        setPagination(headers) {
            this.pagination.total = headers["x-wp-total"] * 1;
            this.pagination.totalPages = headers["x-wp-totalpages"] * 1;
        },
        setCidAndInitLoad(routeName) {
            // 初始化 ID
            // 根据路由名称加载不同案例
            switch (routeName) {
                case "ProjectList":
                    this.cid = this.$env == "prod" ? 69 : 67;
                    break;
                default:
                    this.cid = 3;
                    break;
            }

            // 初始化状态
            this.loadMoreStatus = true;
            this.list = [];
            this.pagination = {
                currentPage: 0,
                pageSize: 10,
                total: 0,
                totalPages: 100,
            };
            // 初始化分页

            this.loadMore();
            this.getNewsListBySticky(this.cid);
        },
        // categories
        // 1 = 未发布
        // 2 = 行业资讯
        // 3 = 集团资讯
        // 67 = 工程案例
        // 获取置顶的列表
        async getNewsListBySticky(categories) {
            let posts = await apiWpV2.getPosts({
                categories: categories,
                page: 1,
                per_page: 10,
                sticky: true,
            });
            this.stickyPosts = posts.data;
        },
        // 获取常规列表
        async getNewsList(categories) {
            let self = this;
            let posts = await apiWpV2.getPosts({
                categories: categories,
                page: self.pagination.currentPage,
                per_page: self.pagination.pageSize,
                sticky: false,
            });
            this.setPagination(posts.headers);
            if (this.list.length == 0) {
                this.list = posts.data;
            } else {
                this.list = this.list.concat(posts.data);
            }
            // this.list = posts.data;
        },
        // 加载更多
        loadMore() {
            let self = this;
            if (self.pagination.currentPage >= self.pagination.totalPages) {
                self.loadMoreStatus = false;
                return;
            }
            self.pagination.currentPage++;
            self.getNewsList(self.cid);
        },
    },
};
</script>
<style lang="scss">
.news-block {
    &__load-more {
        padding: 20px 0 40px;
        text-align: center;
        .cb-primary {
            cursor: pointer;
            padding: 5px 20px;
        }
        .no-more {
            color: #999;
            font-size: 14px;
        }
    }
}
.mobile {
    .news-block {
        &__load-more {
            text-align: center;
            .cb-primary {
                padding: 5px 20px;
            }
        }
    }
}
</style>
