<template>
    <div class="one">
        <el-carousel
            class="one-carousel"
            :autoplay="false"
            ref="oneCarousel"
            indicator-position="none"
            arrow="never"
        >
            <el-carousel-item
                class="one-carousel__item"
                v-for="(item, index) in postsData"
                :key="item.id"
            >
                <div class="one-content">
                    <div class="one-content__left">
                        <el-image
                            class="co__img"
                            fit="cover"
                            :src="item.thumb_image"
                        ></el-image>
                    </div>
                    <div class="one-content__right">
                        <div class="one-content__title">
                            <router-link
                                :to="{
                                    name: 'NewsDetail',
                                    params: { contentId: item.id },
                                }"
                                ><h2>{{ item.title.rendered }}</h2></router-link
                            >
                        </div>
                        <div class="one-content__date">
                            {{ item.date | formatDate("yyyy-MM-dd") }}
                        </div>
                        <div
                            class="one-content__desc"
                            v-html="item.excerpt.rendered"
                        ></div>
                        <div class="one-content__link">
                            <router-link 
                            :to="{
                                name: 'NewsDetail',
                                params: { contentId: item.id },
                            }"
                            >查看详情</router-link><v-icon class="co__action-icon" name="md-chevronright" ></v-icon >
                        </div>
                        <div class="one-content__action">
                            <div class="one-carousel__buttons">
                                <span
                                    v-on:click="carousel_prev()"
                                    class="one-carousel__button prev"
                                    ><v-icon
                                        class="one-carousel__button-icon"
                                        name="bi-arrow-left-circle-fill"
                                    ></v-icon
                                ></span>
                                <span class="one-carousel__button-text"
                                    >{{ index + 1 }} / {{ postsData.length }}</span
                                >
                                <span
                                    v-on:click="carousel_next()"
                                    class="one-carousel__button next"
                                    ><v-icon
                                        class="one-carousel__button-icon"
                                        name="bi-arrow-right-circle-fill"
                                    ></v-icon
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <!-- <div class="one-carousel__control">
            <div class="one-carousel__buttons">
                <span v-on:click="carousel_prev()" class="one-carousel__button prev"
                    ><v-icon
                        class="one-carousel__button-icon"
                        name="bi-arrow-left-circle-fill"
                    ></v-icon
                ></span>
                <span class="one-carousel__button-text">1 / 3</span>
                <span  v-on:click="carousel_next()" class="one-carousel__button next"
                    ><v-icon
                        class="one-carousel__button-icon"
                        name="bi-arrow-right-circle-fill"
                    ></v-icon
                ></span>
            </div>
        </div> -->
    </div>
</template>
<script>
import Vue from "vue";
import { Carousel, CarouselItem, Image } from "element-ui";
Vue.use(Carousel).use(CarouselItem).use(Image);

// https://static.fun2ex.com/w1.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w2.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w3.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w4.jpg?imageMogr2/thumbnail/1920x

export default {
    name: "HomeCarousel",
    props: {
        postsData: Array,
    },
    data() {
        return {};
    },
    mounted() {
        this.carousel_count();
    },
    methods: {
        carousel_prev() {
            // prev() {
            // console.log(this.$refs)
            this.$refs.oneCarousel.prev();
            // },
            // next() {
            // }
        },
        carousel_next() {
            this.$refs.oneCarousel.next();
        },
        carousel_count() {},
    },
};
</script>
<style lang="scss">
.one {
    position: relative;

    &-carousel {
        width: 100%;
        height: 514px;

        &__item {
            height: 514px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

        &__buttons {
            display: flex;
            align-items: center;
        }
        &__button {
            color: #1b70f0;
            background: #fff;
            border-radius: 40px;
            width: 40px;
            height: 40px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            &-text {
                padding: 0 10px;
                // color: rgba(204, 221, 237, 1);
                color: #000;
            }

            &-icon {
                width: 50px;
                height: 50px;
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }

        &__control {
            position: absolute;
            z-index: 2;
            bottom: 20%;
            right: 350px;
            transform: translate(-50%, 0);
            // transform:
        }
    }

    &-content {
        height: 514px;
        width: 100%;
        // background: rgba(0, 0, 0, 0.2);
        color: #000;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        &__left {
            padding-right: 60px;
            width: 800px;
            height: 514px;
            flex: none;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }

            .co__img {
                width: 100%;
            }
        }
        &__right {
            padding: 20px 0;
        }

        &__other {
            .text {
                font-size: 18px;
                font-weight: 600;
                text-transform: uppercase;
            }
            .bottom-line {
                width: 60px;
                height: 2px;
                margin-top: 10px;
                background: #000;
            }
        }

        &__title {
            font-size: 20px;
            padding-bottom: 20px;
        }

        &__date {
            padding-bottom: 30px;
        }

        &__desc {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.8;
            color: #666666;
        }

        &__link {
            padding-top: 20px;
            text-align: right;
            color: #1b70f0;
            a {
                color: #1b70f0;
            }
        }
        &__action {
            padding-top: 30px;
        }

        // &__action {
        //     padding-top: 80px;
        //     .button-link {
        //         display: block;
        //         width: 180px;
        //         height: 60px;
        //         background: #1b70f0;
        //         border-radius: 30px;
        //         color: #fff;
        //         text-align: center;
        //         line-height: 60px;

        //         &:hover {
        //             box-shadow: 0px 2px 14px 0px rgba(0, 85, 165, 0.2);
        //             text-decoration: none;
        //         }
        //     }
        // }
    }
}
.mobile {
    .one {
        &-content {
            width: 90vw;
            margin: 0 auto;
            height: auto;
            display: block;

            &__left {
                padding: 0;
                width: 90vw;
                height: auto;

                .el-image {
                    display: block;
                }
            }
            &__right {
                padding: 0;
            }

            &__title {
                font-size: 14px;
                padding-bottom: 3vw;
                padding-top: 5vw;
            }
            &__date {
                font-size: 12px;
                padding-bottom: 3vw;
            }
            &__desc {
                font-size: 13px;
                height: 36vw;
                overflow: hidden;
            }
            &__link {
                padding-top: 5vw;
                font-size: 12px;
                display: flex;
                align-content: center;
                justify-content: center;
            }
            &__action {
                display: block;
                width: 35vw;
                margin: 0 auto;
                padding-top: 3vw;
                .one-carousel__buttons {
                    justify-content: space-between;
                    font-size: 13px;
                }
                .one-carousel__button {
                    width: 30px;
                    height: 30px;
                    border-radius: 40px;
                    background: #999;
                    font-size: 12px;
                    color: #fff;
                    border: 1px solid #999;
                    cursor: pointer;
                    &:hover {
                        background: #1b70f0;
                        color: #fff;
                        border: 1px solid #1b70f0;
                    }
                    &-icon {
                        padding: 0;
                        margin: 0;
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        &-carousel {
            height: 150vw;
        }

        .el {
            &-carousel {
                &__container {
                    height: 150vw;
                }
                &__item {
                    height: 150vw;
                }
            }
        }
    }
}
</style>
