<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/about-culture@2x.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__culture">
                    <div class="slogan-text">开放 / 创新 / 和谐 / 务实</div>
                    <div class="slogan-desc">
                        面向未来，积极学习，勇于尝试，诚信包容；创新机制，持续改进，追求卓越，不断成长；目标一致，真诚沟通，责任共担，利益共享；追求实效，稳健进取，理性决策，勤勉工作。
                    </div>
                </div>
            </div>
        </div>

        <div class="page-block">
            <div class="hd hd-culture__block">
                <div class="ht width-master">
                    <div class="meta">
                        <i class="line"></i>
                        <span class="text">Company Culture</span>
                    </div>
                    <h2>企业文化</h2>
                </div>
                <div class="hc">
                    <div class="culture-item culture-item__company">
                        <div class="width-master">
                            <div class="culture-item__b1 culture-item__values">
                                <div class="title">核心价值观</div>
                                <div class="content">
                                    <div class="l1">
                                        <span class="spc">精益求精</span>
                                        质量第一，客户至上，追求最高的高客户满意度。
                                    </div>
                                    <div class="l1">
                                        <span class="spc">精益求精</span>
                                        尊重人才，提供机会，回报与贡献匹配，终身学习，持续提升。
                                    </div>
                                    <div class="l1">
                                        <span class="spc">精益求精</span>
                                        沟通合作，相互信任，主动承担责任，利益共享。
                                    </div>
                                </div>
                            </div>
                            <div class="culture-item__b1 culture-item__mission">
                                <div class="title">核心价值观</div>
                                <div class="content">
                                    <div class="l1">
                                        <span class="spc"
                                            >雄心永固，塑造未来</span
                                        >
                                        努力为客户创造价值，我们坚持以客户为中心，提供有效服务，是我们工作
                                        的方向和价值评价的标尺，成就客户就是成就我们自己。
                                        为员工创造机会，
                                        为股东创造利润，为社会创造财富，实现振兴民族工业的伟大理想。
                                    </div>
                                </div>
                            </div>
                            <div class="culture-item__b1 culture-item__vision">
                                <div class="title">核心价值观</div>
                                <div class="content">
                                    <div class="l1">
                                        <span class="spc"
                                            >做塑胶精品，树国际品牌</span
                                        >
                                        我们致力于成为国内管道行业的领导者，跻身全国管道行业前列，我们将不
                                        断提升境界、放眼全球，以全球化经营的理念为指引，
                                        瞄准国际领先企业，
                                        不断创新、深化改革、培养人才，打造具有全球优势的企业价值链，使雄塑
                                        成为全球的知名品牌。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-block">
            <div class="hd hd-brands__block">
                <div class="hd-inside">
                    <div class="ht">
                        <div class="meta">
                            <i class="line"></i>
                            <span class="text">Company Brands</span>
                        </div>
                        <h2>企业品牌</h2>
                    </div>
                    <div class="hc">
                        <div class="brands-list">
                            <div class="brands-item">
                                <div class="title">产品优势转移到企业品牌</div>
                                <div class="content">
                                    企业通过管理整合、技术整合、市场整合、文化整合，为产品品牌搭建更为广阔的舞台，产品品质得到优化，货源供应更加充足，市场反应更加迅速，满足社会需求快捷准确。这些都是销售商感兴趣的，消费者也会通过强大的企业形象塑造从而对产品品牌产生认知和信赖。
                                </div>
                            </div>
                            <div class="brands-item">
                                <div class="title">企业文化的传播</div>
                                <div class="content">
                                    企业文化主要是解决企业与消费者、员工、社会及合作伙伴之间的关系，让销售商、
                                    消费者感到亲近、温馨，感到受尊重，对企业的产品自然会产生好感。
                                </div>
                            </div>
                            <div class="brands-item">
                                <div class="title">关爱社群与公益活动</div>
                                <div class="content">
                                    企业的终极意义，不只是盈利，还有超越利润之上的更高追求。因为企业不仅仅为自
                                    己而生存、而发展，更是社会组成的细胞，是企业公民，具有建设和谐社会的责任。
                                </div>
                            </div>
                            <div class="brands-item">
                                <div class="title">营销传播与品牌</div>
                                <div class="content">
                                    企业产品整合营销传播，必须遵循品牌成长的规律，更要遵循品牌资产生成的规则，
                                    从知名度、认知度、美誉度、忠诚度、环保度，将企业品牌根植于客户的心中。
                                </div>
                            </div>
                            <div class="brands-item">
                                <div class="title">企业目标铸造品牌</div>
                                <div class="content">
                                    任何一个企业，必须牢记自己的目标，是消费者，而不是竞争对手。任何一个企业成功的路径，是满足消费者的结果，而不是消灭竞争对手的结果。品牌的影响力，实际上是对消费者的一种观念竞争、人心竞争。这种竞争不是产品本身完全可以做得到，我们要影响消费者，这是我们市场的最终目标。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem } from "element-ui";

Vue.use(Breadcrumb).use(BreadcrumbItem);

export default {
    name: "InvestorRelations",
    components: {},
    data() {
        return {
            list: [],
        };
    },
};
</script>
<style lang="scss">
.company-culture {
    .page-banner {
        color: #252b32;
        &__inside {
            text-align: left;
            padding-right: 0;
        }
        &__title {
            font-size: 50px;
            padding-bottom: 10px;
        }
        &__subtitle {
            font-size: 28px;
        }
        &__culture {
            padding-top: 100px;
        }

        .slogan-text {
            font-size: 50px;
            font-weight: bold;
            padding-bottom: 10px;
        }

        .slogan-desc {
            font-size: 18px;
        }
    }
}
.hd {
    &-culture {
        &__block {
            padding-top: 50px;
            .ht {
                position: relative;
                &::before {
                    position: absolute;
                    right: 0;
                    top: 0;
                    content: "Company Culture";
                    font-family: PingFangSC;
                    font-size: 60px;
                    line-height: 70px;
                    text-transform: uppercase;

                    color: #e8e8e8;
                }
            }
            .hc {
                padding-top: 50px;
            }
        }
    }

    .culture {
        &-item__company {
            height: 730px;
            position: relative;
            display: flex;
            align-items: center;

            &::before {
                content: " ";
                width: 800px;
                height: 730px;
                background: url(~@/assets/image/be-active.jpg) no-repeat;
                background-size: cover;
                background-position: center center;
                position: absolute;
                top: 0;
                left: 0;
            }

            .width-master {
                box-sizing: border-box;
                padding-left: 800px;
            }
        }
        &-item__b1 {
            padding-top: 15px;
            padding-bottom: 15px;
            .title {
                font-size: 32px;
                background: url(~@/assets/image/icon-research.png) no-repeat;
                background-size: auto 30px;
                background-position: left center;
                padding-left: 45px;
                margin-left: -45px;
                margin-bottom: 10px;
            }
            .content {
                line-height: 1.85;
            }
            .spc {
                color: #1b70f0;
                font-weight: bold;
            }
            .l1 {
                padding-bottom: 10px;
            }
        }
        &-item__mission,
        &-item__vision {
            .spc {
                display: block;
                padding-bottom: 10px;
            }
        }
    }

    &-brands__block {
        background: url(~@/assets/image/bg-brands.jpg) no-repeat;
        background-size: cover;
        background-position: center center;
        height: 1134px;
        color: #fff;

        .ht {
            text-align: center;
            padding: 55px 0 25px;

            .meta {
                justify-content: center;
                margin-bottom: 0 auto;
                .line {
                    background: #fff;
                }
            }
        }

        .hc {
            display: flex;
            flex-wrap: wrap;
            width: 1000px;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            height: 900px;

            .brands-list {
                color: #fff;
                line-height: 1.6;
            }

            .brands-item {
                padding: 30px 0;
                .title {
                    font-size: 32px;
                    margin-bottom: 15px;
                    background: url(~@/assets/image/icon-brands.png) no-repeat;
                    background-size: auto 30px;
                    background-position: left center;
                    padding-left: 45px;
                }
                .content {
                    font-size: 16px;
                }
            }
        }
    }
}
.mobile {
    .company-culture {
        .page-banner {
            height: 70vw;
            &__inside {
                text-align: center;
                padding: 0;
            }
            &__title {
                font-size: 20px;
                padding-bottom: 2vw;
            }
            &__subtitle {
                font-size: 12px;
            }
            &__culture {
                padding: 0;
                font-size: 14px;
                .slogan-text {
                    padding-top: 5vw;
                    font-size: 6vw;
                }
                .slogan-desc {
                    font-size: 3.5vw;
                    width: 72vw;
                    text-align: center;
                    margin: 0 auto;
                }
            }
        }

        .culture {
            &-item {
                &__company {
                    display: block;
                    width: 90vw;
                    margin: 0 auto;
                    height: auto;
                    .width-master {
                        box-sizing: border-box;
                        padding: 0;
                        width: 90vw;
                    }

                    &::before {
                        width: 90vw;
                        height: 50vw;
                        position: relative;
                        display: block;
                    }
                }
                &__b1 {
                    font-size: 12px;
                    .title {
                        font-size: 16px;
                        margin-left: 0;
                        background-size: auto 60%;
                        padding-left: 6vw;
                    }
                    .content {
                        padding: 0 6vw;
                        line-height: 1.6;
                    }
                    .spc {
                        display: block;
                    }
                    .l1 {

                    }
                }
            }
        }
        .hd {
            &-culture {
                &__block {
                    padding-top: 5vw;
                    .ht {
                        &::before {
                            display: none;
                        }
                    }
                    .hc {
                        padding-top: 5vw;
                    }
                }
            }
            &-brands {
                &__block {
                    height: auto;
                    background-attachment: fixed;
                    .ht {
                        padding-top: 5vw;
                        text-align: left;
                        .meta {
                            justify-content: flex-start;
                        }
                    }
                    .hc {
                        height: auto;
                        width: 90vw;
                        margin: 0 auto;

                        .brands-item {
                            .title {
                                font-size: 16px;
                        margin-left: 0;
                        background-size: auto 60%;
                        padding-left: 6vw;
                        // padding-bottom: 3vw;
                        margin-bottom: 2vw;
                            }
                            .content {
                                font-size: 12px;
                                line-height: 1.6;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
