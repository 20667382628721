// import ajax from "@/libs/ajax";
import axios from "axios";

const baseURL = "/contact-form-7/v1";
const cf7 = {
    SaveToCFfeedback: (params) => {
        //  姓名: [coo-name]
        //  电话: [coo-phone]
        //  邮箱（可选）: [coo-mail]
        //  传真（可选）: [coo-fax]
        //  公司名称: [coo-company]
        //  公司网站（可选）: [text coo-site]
        //  公司地址: [coo-address]
        //  留言信息: [coo-content]


        // let contactData = new FormData();
        // contactData.append("coo-name", params.name);
        // contactData.append("coo-phone", params.phone);
        // contactData.append("coo-mail", params.mail);
        // contactData.append("coo-fax", params.fax);
        // contactData.append("coo-company", params.company);
        // contactData.append("coo-site", params.site);
        // contactData.append("coo-address", params.address);
        // contactData.append("coo-content", params.content);
    
        // let contactData = {
        //     "coo-name": params.name,
        //     "coo-phone": params.phone,
        //     "coo-mail": params.mail,
        //     "coo-fax": params.fax,
        //     "coo-company": params.company,
        //     "coo-site": params.site,
        //     "coo-province": params.province,
        //     "coo-county": params.county,
        //     "coo-district": params.district,
        //     "coo-address": params.address,
        //     "coo-content": params.content,
        // };

        let contactData = new FormData();
        contactData.append("coo-name", params.name);
        contactData.append("coo-phone", params.phone);
        contactData.append("coo-mail", params.mail);
        contactData.append("coo-fax", params.fax);
        contactData.append("coo-company", params.company);
        contactData.append("coo-site", params.site);
        contactData.append("coo-province", params.province);
        contactData.append("coo-county", params.county);
        contactData.append("coo-district", params.district);
        contactData.append("coo-address", params.address);
        contactData.append("coo-content", params.content);
        contactData.append("coo-file", params.attachment);

        return axios({
            method: "post",
            url: `${baseURL}/contact-forms/${params.type}/feedback`,
            data: contactData,
            // transformRequest: [
            //     function (data) {
            //         let ret = "";
            //         for (let it in data) {
            //             ret +=
            //                 encodeURIComponent(it) +
            //                 "=" +
            //                 encodeURIComponent(data[it]) +
            //                 "&";
            //         }
            //         ret = ret.substring(0, ret.lastIndexOf("&"));
            //         return ret;
            //     },
            // ],
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

    //     console.log(contactData);
    //     return axios.request({
    //         url: `${baseURL}/contact-forms/${params.type}/feedback`,
    //         params: contactData,
    //         method: "POST",
    //     });
    },
};

export default cf7;
