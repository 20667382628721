import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ListOne from "../views/ListOne.vue";
import Detail from "../views/Detail.vue";
import JoinUS from "../views/JoinUS.vue";
import Cooperation from "../views/Cooperation.vue";
// import InvestorRelations from "../views/investor-relations/IRIndex.vue";
import IRSecurities from "../views/investor-relations/IRSecurities.vue";
import IRCompany from "../views/investor-relations/IRCompany.vue";
import IRStockholder from "../views/investor-relations/IRStockholder.vue";
import IRInfomation from "../views/investor-relations/IRInfomation.vue";
import IRInteractive from "../views/investor-relations/IRInteractive.vue";
import IRProtect from "../views/investor-relations/IRProtect.vue";
import ProductsIndex from "../views/products/Index.vue";
import ProductsDetail from "../views/products/Detail.vue";
import AboutTeam from "../views/about/Team.vue";
import AboutPublicWelfare from "../views/about/PublicWelfare.vue";
import AboutCompanyCulture from "../views/about/CompanyCulture.vue";
import AboutProductionBase from "../views/about/ProductionBase.vue";

Vue.use(VueRouter);

let metaBase = {
    ir: {
        title: "投资者关系",
        en_title: "Investor Relations",
        desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
        class: "page-investor-relations",
    },
};

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: "雄塑集团",
            en_title: "Home",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "home-index",
        },
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },

    // 新闻资讯
    {
        path: "/news/list",
        name: "NewsList",
        component: ListOne,
        meta: {
            title: "新闻资讯",
            en_title: "News",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "news-list",
        },
    },
    {
        path: "/news/detail/:contentId",
        name: "NewsDetail",
        component: Detail,
        meta: {
            title: "新闻资讯",
            class: "news-detail",
        },
    },

    // 工程案例
    {
        path: "/products",
        name: "Products",
        component: ProductsIndex,
        meta: {
            title: "雄塑产品",
            en_title: "Products",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "page-products",
        },
    },
    {
        path: "/products/detail/cid/:productCategoryId/pds/:postId",
        name: "ProductsDetail",
        component: ProductsDetail,
        meta: {
            title: "雄塑产品",
            en_title: "Products",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "page-products-detail",
        },
    },
    {
        path: "/project/list",
        name: "ProjectList",
        component: ListOne,
        meta: {
            title: "工程案例",
            en_title: "Project Case",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "project-list",
        },
    },
    {
        path: "/project/detail/:contentId",
        name: "ProjectDetail",
        component: Detail,
        meta: {
            title: "工程案例",
            class: "project-detail",
        },
    },

    // 加入我们
    {
        path: "/about/team",
        name: "AboutUS",
        component: AboutTeam,
        meta: {
            title: "集团介绍",
            en_title: "About US",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "about-us",
        },
    },
    {
        path: "/about/production-base",
        name: "ProductionBase",
        component: AboutProductionBase,
        meta: {
            title: "关于雄塑",
            en_title: "About Homso",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "production-base",
        },
    },
    {
        path: "/about/company-culture",
        name: "CompanyCulture",
        component: AboutCompanyCulture,
        meta: {
            title: "关于雄塑",
            en_title: "About Homso",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "company-culture",
        },
    },
    {
        path: "/about/public-welfare",
        name: "PublicWelfare",
        component: AboutPublicWelfare,
        meta: {
            title: "雄塑公益",
            en_title: "Public Welfare",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "public-welfare",
        },
    },
    // {
    //     path: "/about/detail/:contentId",
    //     name: "AboutDetail",
    //     component: Detail,
    //     meta: {
    //         title: "加入我们",
    //         class: "about-detail",
    //     },
    // },
    {
        path: "/join-us",
        name: "JoinUS",
        component: JoinUS,
        meta: {
            title: "招贤纳士",
            en_title: "Join Us",
            class: "join-us",
        },
    },

    {
        path: "/cooperation",
        name: "Cooperation",
        component: Cooperation,
        meta: {
            title: "合作服务",
            en_title: "Cooperation",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "page-cooperation",
        },
    },

    // 投资者关系
    // Investor Relations

    {
        path: "/investor-relations",
        name: "InvestorRelations",
        // component: InvestorRelations,
        redirect: {
            name: "IRSecurities",
        },
        meta: {
            title: "投资者关系",
            en_title: "Investor Relations",
            desc: "打造具有全球优势的企业价值链，使雄塑成为全球的知名品牌。",
            class: "page-investor-relations",
        },
    },
    {
        path: "/investor-relations/securities",
        name: "IRSecurities",
        component: IRSecurities,
        meta: Object.assign({}, metaBase.ir, { sub_title: "证券概括" }),
    },
    {
        path: "/investor-relations/company",
        name: "IRCompany",
        component: IRCompany,
        meta: Object.assign({}, metaBase.ir, { sub_title: "公司治理" }),
    },
    {
        path: "/investor-relations/stockholder",
        name: "IRStockholder",
        component: IRStockholder,
        meta: Object.assign({}, metaBase.ir, { sub_title: "股东回报" }),
    },
    {
        path: "/investor-relations/infomation",
        name: "IRInfomation",
        component: IRInfomation,
        meta: Object.assign({}, metaBase.ir, { sub_title: "信息披露" }),
    },
    {
        path: "/investor-relations/interactive",
        name: "IRInteractive",
        component: IRInteractive,
        meta: Object.assign({}, metaBase.ir, { sub_title: "投资者互动" }),
    },
    {
        path: "/investor-relations/protect",
        name: "IRProtect",
        component: IRProtect,
        meta: Object.assign({}, metaBase.ir, { sub_title: "投资者保护" }),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.afterEach(() => {
    // 应为 Nav 使用了 fixed 的方式，故在每个导航跳转的时候回到顶部
    window.scrollTo(0, 0);
});

export default router;
