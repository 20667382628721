<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/investor-relations.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block ir-block__top">
            <div class="page-breadcrumb width-master">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
                </el-breadcrumb>
                <InvestorRelationsNav></InvestorRelationsNav>
            </div>

        </div>

         <div class="page-block width-master">
            <div class="ir-block__hd ir-block__list">
                <div
                    class="list-item"
                    v-for="(item, index) in list"
                    :key="index"
                >
                    <div class="text">
                        <a target="_blank" :href="item.acf.file">
                            {{item.title.rendered}}
                        </a>
                    </div>
                    <span class="date">{{ item.date | formatDate("yyyy-MM-dd") }}</span>
                </div>
            </div>
            <div class="page-pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pagination.currentPage"
                    layout="total, prev, pager, next"
                    :page-size="pagination.pageSize"
                    :total.sync="pagination.total"
                    background
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem } from "element-ui";
import InvestorRelationsNav from "@/components/InvestorRelationsNav.vue";
// getpProtection
import apiWpV2 from "@/api/wp";

Vue.use(Breadcrumb).use(BreadcrumbItem);

export default {
    name: "IRSecurities",
    components: {
        InvestorRelationsNav
    },
    data() {
        // https://uat.xiongsu.cn/wp-json/wp/v2/protection?per_page=20
        return {
            list: [],
            pagination: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                totalPages: 0
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        setPagination(headers) {
            this.pagination.total = headers["x-wp-total"] * 1;
            this.pagination.totalPages = headers["x-wp-totalpages"] * 1;
        },
        async getProtection(config) {
            const res = await apiWpV2.getProtection(config);
            this.setPagination(res.headers);
            this.list = res.data;
            // 回到首页
            window.scrollTo(0, 0);
        },
        init() {
            this.getProtection({
                page: 1,
                per_page: 15
            });
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.getProtection({
                page: val,
                per_page: 15
            });
        },
    }
};
</script>
<style lang="scss">
@import "~@/assets/style/investor-relations.scss";
</style>
