<template>
  <div class="logo-block">
    <div class="logo__bg"></div>
    <div class="stock__info">股票代码：300599</div>
  </div>
</template>
<script>
export default {
  name: "Logo",
};
</script>
<style lang="scss">
.logo {
  &-block {
    width: 161px;

    .stock__info {
      color: #0055a5;
      font-size: 12px;
      font-weight: bold;
      padding-top: 2px;
      text-align: left;
    }
  }
  &__bg {
    height: 21px;
    background: url(../assets/image/logo-color@2x.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;
  }
}

.header-logo{
    &__white {
        .logo__bg {
            background-image: url(../assets/image/logo-white@2x.png);
        }
        .stock__info {
            color: #fff;
        }
    }

}
.mobile {
    .header-logo{
    &__white {
        .logo__bg {
            background-image: url(../assets/image/logo-color@2x.png);
        }
        .stock__info {
            color: #999;
        }
    }

}
}
</style>
