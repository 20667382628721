<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/about-public-welfare@2x.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">
                    雄塑公益捐款金额累计约（元）
                </div>
                <div class="page-banner__subtitle">26,400,000</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block width-master">
            <div class="public-welfare__block">
                <div class="public-welfare__left">
                    <div class="timeline-block">
                        <div
                            v-on:click="showPost(index)"
                            class="timeline-item"
                            :class="{ 'timeline-item__active': index === activeIndex }"
                            v-for="(item, index) in pwList"
                            :key="index"
                        >
                            <div class="year">{{ item.acf.year }}</div>
                            <div class="count">{{ item.acf.count }}</div>
                            <div class="desc" v-html="item.acf.intro"></div>
                        </div>
                    </div>
                </div>
                <div class="public-welfare__right">
                    <div class="page-content post">
                        <div class="post-title">
                            <div class="year">{{ post.acf.year }}</div>
                            <h2 v-html="post.title.rendered"></h2>
                            <div class="meta">{{ post.acf.meta }}</div>
                        </div>
                        <div
                            class="post-content"
                            v-html="post.acf.content"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem } from "element-ui";
import apiWpV2 from "@/api/wp";

Vue.use(Breadcrumb).use(BreadcrumbItem);

export default {
    name: "PublicWelfare",
    components: {},
    data() {
        return {
            pwList: [],
            activeIndex: 0,
            post: {
                acf: {
                    year: "",
                    meta: "",
                    content: ""
                },
                title: {
                    rendered: ""
                }
            },
        };
    },
    mounted() {
        this.getPwList();
    },
    methods: {
        async getPwList() {
            const res = await apiWpV2.getPublicWelfare({
                page: 1,
                per_page: 50,
            });
            // set default post
            this.pwList = res.data;
            this.showPost(0);
        },
        showPost(index) {
            this.activeIndex = index;
            this.post = this.pwList[index];
        },
        init() {
            this.getPwList();
        },
    },
};
</script>
<style lang="scss">
.public-welfare {
    .page-banner {
        &__title {
            font-size: 28px;
            padding-bottom: 20px;
        }
        &__subtitle {
            font-size: 60px;
            background: url(~@/assets/image/icon-pw.png) no-repeat;
            background-size: auto 30px;
            background-position: left center;
            display: inline-block;
            padding-left: 40px;
            margin-left: -40px;
            margin-bottom: 40px;
        }
    }

    &__block {
        padding-top: 10px;
        display: flex;
    }

    &__left {
        width: 400px;
        padding-top: 80px;
    }
    &__right {
        flex: 1;
    }

    .timeline {
        &-block {
            display: inline-block;
            border-left: 2px solid #ececec;
            position: relative;
            padding: 16px 0;
            &::before,
            &::after {
                width: 16px;
                height: 16px;
                box-sizing: border-box;
                content: " ";
                font-size: 0;
                border: 1px solid #ececec;
                position: absolute;
                left: -8px;
                background: #fff;
                border-radius: 100px;
            }
            &::before {
                top: 0;
            }
            &::after {
                bottom: 0;
            }
        }

        &-item {
            color: #666;
            line-height: 1.6;
            padding-top: 30px;
            display: block;
            padding-left: 35px;
            cursor: pointer;

            &:hover {
                text-decoration: none;
                color: #000;
            }

            &:first-child {
                padding-top: 0;
            }

            .year {
                font-size: 30px;
                color: #333;
                padding-bottom: 5px;
            }
            .count {
                font-size: 20px;
            }
            .desc {
                font-size: 16px;
            }

            &__active {
                color: #1b70f0;
                .year {
                    color: #1b70f0;
                    font-weight: bold;
                    font-size: 38px;
                }

                &:hover {
                    color: #1b70f0;
                }
            }
        }
    }

    .post {
        border-top: 0;
        padding-left: 30px;
    }

    .post-content {
        padding: 0;
    }
    .post-title {
        border-bottom: 1px solid #ccc;
        padding-bottom: 30px;
        margin-bottom: 30px;
        line-height: 1.6;

        .year {
            font-size: 54px;
        }
        h2 {
            font-size: 28px;
        }
        .meta {
            font-size: 18px;
            font-weight: 100;
        }
    }
}
.mobile {
    .public-welfare {
        .page-banner {
            height: 30vw;
            background-position: 0 center;
            box-sizing: border-box;
            padding-right: 40vw;
            &__inside {
                text-align: center;
                width: 60vw;
                padding: 0;
            }
            &__title {
                font-size: 10px;
                padding-bottom: 1vw;
                font-weight: normal;
            }
            &__subtitle {
                font-size: 14px;
                background-size: auto 75%;
                margin: 0;
                padding: 0;
                margin-left: -6vw;
                padding-left: 6vw;
                font-weight: bold;
            }
        }
    }
    .public-welfare {
        &__block {
            display: block;
        }
        &__right {
            display: none;
        }
        &__left {
            padding-top: 0;
            width: 90vw;
            margin: 0 auto;
        }
        .timeline-block {
            padding-top: 0;
            border: 0 none;
            display: block;
            &::before,
            &::after {
                display: none;
            }
        }
        .timeline-item {
            padding-left: 0;
            border-bottom: 1px solid #ddd;
            padding-bottom: 5vw;
            padding-top: 5vw;
            .year {
                font-size: 20px;
            }
            .count {
                font-size: 14px;
            }
            .desc {
                font-size: 12px;
            }
            &:last-child {
                border-bottom: 0 none;
            }
        }
    }
}
</style>
