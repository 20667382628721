<template>
    <div class="two">
        <el-carousel
            class="two-carousel"
            :autoplay="false"
            ref="oneCarousel"
            indicator-position="none"
            arrow="never"
        >
            <el-carousel-item
                class="two-carousel__item"
                :class="{ 'dark-model': item.dark_model }"
                v-for="(item, index) in detailData"
                :key="item.id"
            >
                <div class="two-content">
                    <div class="two-content__left">
                        <img :src="item.image" alt="" />
                    </div>
                    <div class="two-content__right">
                        <div class="two-content__title">
                            <h2>{{ item.name }}</h2>
                        </div>
                        <div class="two-content__date">
                            {{ item.date | formatDate("yyyy-MM-dd") }}
                        </div>
                        <div
                            class="two-content__desc"
                            v-html="item.desc"
                        ></div>
                        <div class="two-content__color">
                            <div v-show="item.color.length" class="ht">颜色</div>
                            <div v-show="item.color.length" class="hd">
                                <div class="color-list">
                                    <div class="color-list__item" v-for="(color, index) in item.color" :key="index" @click="swotchColorImage(color.image)">
                                        <span :style="{
                                            'background': color.color,
                                        }" class="cr-1">red</span>
                                        <span class="cr-text">{{color.name}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="two-content__action">
                            <div class="two-carousel__buttons">
                                <span
                                    v-on:click="carousel_prev()"
                                    class="two-carousel__button prev"
                                    ><v-icon
                                        class="two-carousel__button-icon"
                                        name="bi-arrow-left-circle-fill"
                                    ></v-icon
                                ></span>
                                <span class="two-carousel__button-text"
                                    >{{ index + 1 }} /
                                    {{ detailData.length }}</span
                                >
                                <span
                                    v-on:click="carousel_next()"
                                    class="two-carousel__button next"
                                    ><v-icon
                                        class="two-carousel__button-icon"
                                        name="bi-arrow-right-circle-fill"
                                    ></v-icon
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <!-- <div class="two-carousel__control">
            <div class="two-carousel__buttons">
                <span v-on:click="carousel_prev()" class="two-carousel__button prev"
                    ><v-icon
                        class="two-carousel__button-icon"
                        name="bi-arrow-left-circle-fill"
                    ></v-icon
                ></span>
                <span class="two-carousel__button-text">1 / 3</span>
                <span  v-on:click="carousel_next()" class="two-carousel__button next"
                    ><v-icon
                        class="two-carousel__button-icon"
                        name="bi-arrow-right-circle-fill"
                    ></v-icon
                ></span>
            </div>
        </div> -->
    </div>
</template>
<script>
import Vue from "vue";
import { Carousel, CarouselItem } from "element-ui";
Vue.use(Carousel).use(CarouselItem);

// https://static.fun2ex.com/w1.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w2.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w3.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w4.jpg?imageMogr2/thumbnail/1920x

export default {
    name: "HomeCarousel",
    props: {
        detailData: Array,
    },
    data() {
        return {
            one: [
                {
                    name: "PVC难燃电线槽管",
                    desc: "1956 年，拿历史书上的话说，是中国半导体行业发展史上的具有里程碑意义的一年。那一年，在我国十二年科学技术发展远景规划中，半导体科学技术被列为当时国家新技术四大紧急措施之一。那个时候，产业界还在电子管时代，集成电路尚未发明。",
                    image: "https://uat.xiongsu.cn/wp-content/uploads/2022/09/avatar.png",
                    color: [
                        {
                            颜色名称: 75,
                            image: "https://uat.xiongsu.cn/wp-content/uploads/2022/03/p-o-4@2x-1.jpg",
                        },
                        {
                            颜色名称: 76,
                            image: "https://uat.xiongsu.cn/wp-content/uploads/2022/03/pp-hero-s@2x-scaled.jpg",
                        },
                        {
                            颜色名称: 79,
                            image: "https://uat.xiongsu.cn/wp-content/uploads/2022/03/research-middle.jpg",
                        },
                    ],
                },
                {
                    name: "国家计划与政策扶持",
                    desc: "例如 1959 年，在天津一个小工厂的“601”实验室（机电部第 46 所前身）拉制出硅（Si）单晶，这其实是中国的第一代半导体人研制出的第一颗具有生产实用价值的硅单晶——天津硅。601 实验室的主要成员，都来自南开大学物理系半导体教研室。",
                    image: "https://uat.xiongsu.cn/wp-content/uploads/2022/09/01.jpg",
                    color: [
                        {
                            颜色名称: 75,
                            image: "https://uat.xiongsu.cn/wp-content/uploads/2021/10/zumper_hackathon_logo.png",
                        },
                    ],
                },
            ],
        };
    },
    mounted() {
        this.carousel_count();
    },
    methods: {
        swotchColorImage(image) {
            if (image) {
                console.info(image)
                return image;
            } else {
                return false;
            }
        },
        hello() {
            alert("ddd");
        },
        carousel_prev() {
            // prev() {
            // console.log(this.$refs)
            this.$refs.oneCarousel.prev();
            // },
            // next() {
            // }
        },
        carousel_next() {
            this.$refs.oneCarousel.next();
        },
        carousel_count() {
            console.info(this.detailData);
        },
    },
};
</script>
<style lang="scss">
.two {
    position: relative;

    &-carousel {
        width: 100%;
        height: 514px;

        &__item {
            height: 514px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

        &__buttons {
            display: flex;
            align-items: center;
        }
        &__button {
            color: #1b70f0;
            background: #fff;
            border-radius: 40px;
            width: 40px;
            height: 40px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            &-text {
                padding: 0 10px;
                // color: rgba(204, 221, 237, 1);
                color: #000;
            }

            &-icon {
                width: 50px;
                height: 50px;
                left: 50%;
                top: 50%;
                position: absolute;
                color: #c3c3c3;
                transform: translate(-50%, -50%);
            }
        }

        &__control {
            position: absolute;
            z-index: 2;
            bottom: 20%;
            right: 350px;
            transform: translate(-50%, 0);
            // transform:
        }
    }

    &-content {
        height: 514px;
        width: 100%;
        // background: rgba(0, 0, 0, 0.2);
        color: #000;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        &__left {
            padding-right: 60px;
            width: 800px;
            height: 514px;
            flex: none;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &__right {
            padding: 20px 0;
            width: 100%;
        }

        &__other {
            .text {
                font-size: 18px;
                font-weight: 600;
                text-transform: uppercase;
            }
            .bottom-line {
                width: 60px;
                height: 2px;
                margin-top: 10px;
                background: #000;
            }
        }

        &__title {
            font-size: 20px;
            padding-bottom: 20px;
        }

        &__date {
            padding-bottom: 30px;
        }

        &__desc {
            // text-overflow: ellipsis;
            // word-break: break-all;

            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 2;
            height: 7em;
            overflow: hidden;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.8;
            color: #666666;
        }

        &__color {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #c3c3c3;
            .ht {
                font-size: 18px;
                padding-bottom: 20px;
            }
            .color-list {
                display: flex;
                text-align: center;
                &__item {
                    cursor: pointer;
                    margin-right: 10px;
                }
                span {
                    display: block;
                }
            }

            .cr-1 {
                background: #f7f7f3;
                box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.2);
                width: 40px;
                height: 40px;
                border-radius: 100px;
                font-size: 0;
            }
            .cr-text {
                font-size: 14px;
                padding-top: 10px;
            }
        }
        &__action {
            padding-top: 30px;
        }
    }
}

.mobile {
    .two {
        &-content {
            width: 90vw;
            margin: 0 auto;
            height: auto;
            display: block;

            &__left {
                padding: 0;
                width: 90vw;
                box-sizing: border-box;
                border: 1px solid #f1f1f1;
                height: auto;

                .el-image {
                    display: block;
                }
            }
            &__right {
                padding: 0;
            }

            &__title {
                font-size: 14px;
                padding-bottom: 3vw;
                padding-top: 5vw;
            }
            &__date {
                font-size: 12px;
                padding-bottom: 3vw;
            }
            &__desc {
                font-size: 11px;
                height: 36vw;
                overflow: hidden;
            }
            &__link {
                padding-top: 5vw;
                font-size: 12px;
                display: flex;
                align-content: center;
                justify-content: center;
            }
            &__color {
                margin: 0;

                .ht {
                    font-size: 13px;
                    padding-bottom: 10px;
                }
                .color-list__item {
                    .cr-1 {
                        width: 30px;
                        height: 30px;
                    }
                    .cr-text {
                        padding-top: 5px;
                        font-size: 10px;
                    }
                }
            }
            &__action {
                display: block;
                width: 35vw;
                margin: 0 auto;
                padding-top: 5vw;
                .two-carousel__buttons {
                    justify-content: space-between;
                    font-size: 13px;
                }
                .two-carousel__button {
                    width: 30px;
                    height: 30px;
                    border-radius: 40px;
                    background: #f1f1f1;
                    font-size: 12px;
                    color: #fff;
                    border: 1px solid #f1f1f1;
                    cursor: pointer;
                    &:hover {
                        background: #1b70f0;
                        color: #fff;
                        border: 1px solid #1b70f0;
                    }
                    &-icon {
                        padding: 0;
                        margin: 0;
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        &-carousel {
            height: 200vw;
        }

        .el {
            &-carousel {
                &__container {
                    height: 200vw;
                }
                &__item {
                    height: 200vw;
                }
            }
        }
    }
}
</style>
