var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container",class:_vm.$route.meta.class},[_c('div',{staticClass:"page-banner",style:(("background-image: url(" + _vm.$publicPath + "/static/banner/" + (_vm.$route.meta.class) + ".jpg?v=20220816);"))},[_c('div',{staticClass:"page-banner__inside width-master"},[_c('div',{staticClass:"page-banner__title"},[_vm._v(_vm._s(_vm.$route.meta.en_title))]),_c('div',{staticClass:"page-banner__subtitle"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('div',{staticClass:"page-banner__desc"},[_vm._v(" "+_vm._s(_vm.$route.meta.desc)+" ")])])]),_c('div',{staticClass:"page-block width-master"},[_c('div',{staticClass:"page-breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.$route.meta.title))])],1)],1)]),_vm._l((_vm.productMap),function(pMap,pIndex){return _c('div',{key:pMap.id + pIndex,staticClass:"products-list"},[_c('div',{staticClass:"product-title width-master"},[_vm._v(_vm._s(pMap.name))]),(pMap.slug != 'sp')?_c('div',{staticClass:"product-hd"},_vm._l((_vm.productData[pMap.id]),function(item,index){return _c('div',{key:item.id,staticClass:"product-model__b",class:'product-model__item-' + (index % 4),style:(("background-image: url(" + (item.acf.image) + ");"))},[_c('div',{staticClass:"content"},[_c('h3',[_vm._v(_vm._s(item.acf.title))]),_c('div',{staticClass:"action"},[_c('router-link',{attrs:{"to":{
                                name: 'ProductsDetail',
                                params: {
                                    productCategoryId: item.acf.category[0],
                                    postId: item.acf.post_id,
                                },
                            },"title":pMap.pds.name}},[_vm._v("了解更多")]),_c('v-icon',{staticClass:"co__action-icon",attrs:{"name":"md-chevronright"}})],1)])])}),0):_vm._e(),(pMap.slug == 'sp')?_c('div',{staticClass:"product-hd"},_vm._l((_vm.productData[pMap.id]),function(item){return _c('div',{key:item.id,staticClass:"product-model__a",style:(("background-image: url(" + (item.acf.image) + ");"))},[_c('div',{staticClass:"content"},[_c('h3',[_vm._v(_vm._s(item.title.rendered))]),_c('div',{staticClass:"sub_h3"},[_vm._v(_vm._s(item.acf.title))]),_c('div',{staticClass:"action"},[_c('router-link',{attrs:{"to":{
                                name: 'ProductsDetail',
                                params: {
                                    productCategoryId: item.acf.category[0],
                                    postId: item.acf.post_id,
                                },
                            },"title":pMap.pds.name}},[_vm._v("了解更多")]),_c('v-icon',{staticClass:"co__action-icon",attrs:{"name":"md-chevronright"}})],1)])])}),0):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }