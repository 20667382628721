<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/${$route.meta.class}.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block width-master">
            <div class="page-breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{
                        $route.meta.title
                    }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="news-block__carousel product-detail__carousel">
                <CarouselPds v-bind:detail-data="detail.acf.list"></CarouselPds>
            </div>
        </div>
        <div class="p-cpys">
            <img class="img" :src="pageSetting.acf.product_advantages" alt="" />
        </div>
        <div class="p-cpys-2">
            <img class="img" :src="pageSetting.acf.product_features" alt="" />
        </div>
    </div>
</template>
<script>
import CarouselPds from "@/components/CarouselPds.vue";
// apiWpV2
import apiWpV2 from "@/api/wp";

export default {
    name: "ProductsDetail",
    components: {
        CarouselPds,
    },
    data() {
        return {
            name: "ProductsDetail",
            detail: {},
            pageSetting: {
                acf: {},
            },
        };
    },
    async mounted() {
        // 缓存起来，避免重复请求 getProductColor
        // if (Object.keys(this.$global.color).length === 0) {
        //     this.$global.color = await this.getProductColor();
        // }

        this.getProductDetail();
        // this.getProductSetting();
    },
    methods: {
        // 获取产品详情
        async getProductDetail() {
            const response = await apiWpV2.getProductDetail({
                id: this.$route.params.postId,
            });
            console.log(response.data);
            this.detail = response.data;

            // 获取页面配置
            const pageSettingRep = await apiWpV2.getProductDetailSetting({
                id: this.detail.acf.page_setting_id,
            });

            this.pageSetting = pageSettingRep.data;
        },
    },
};
</script>
<style lang="scss">
.product-detail__carousel {
    padding-top: 50px;
    padding-bottom: 100px;
}
// .p-cpys {
//     height: 1284px;
// }
// .p-cpys-2 {
//     height: 3300px;
// }
.p-cpys,
.p-cpys-2 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;

    img {
        object-fit: cover;
        width: 100%;
        display: block
    }
}
.product-detail__carousel {
    padding-bottom: 50px;
}
.mobile {
    .product-detail__carousel {
        padding: 0;
    }
}
</style>
