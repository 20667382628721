var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container",class:_vm.$route.meta.class},[_c('div',{staticClass:"page-banner",style:(("background-image: url(" + _vm.$publicPath + "/static/banner/" + (_vm.$route.meta.class) + ".jpg);"))},[_c('div',{staticClass:"page-banner__inside width-master"},[_c('div',{staticClass:"page-banner__title"},[_vm._v(_vm._s(_vm.$route.meta.en_title))]),_c('div',{staticClass:"page-banner__subtitle"},[_vm._v(_vm._s(_vm.$route.meta.title))]),_c('div',{staticClass:"page-banner__desc"},[_vm._v(" "+_vm._s(_vm.$route.meta.desc)+" ")])])]),_c('div',{staticClass:"page-block width-master"},[_c('div',{staticClass:"page-breadcrumb"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.$route.meta.title))])],1)],1),_c('div',{staticClass:"news-block__carousel"},[_c('CarouselOne',{attrs:{"posts-data":_vm.stickyPosts}})],1),_c('div',{staticClass:"news-block__list"},[_c('div',{staticClass:"list-model__a"},_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"list-model__item",class:'list-model__item-' + (index % 4)},[_c('div',{staticClass:"co-left"},[_c('el-image',{staticClass:"co__img",attrs:{"fit":"cover","src":item.thumb_image}})],1),_c('div',{staticClass:"co-right"},[_c('div',{staticClass:"co__action"},[_c('router-link',{attrs:{"to":{
                                    name: 'NewsDetail',
                                    params: { 
                                        contentId: item.id
                                    },
                                }}},[_vm._v("查看详情")]),_c('v-icon',{staticClass:"co__action-icon",attrs:{"name":"md-chevronright"}})],1),_c('div',{staticClass:"co__title"},[_c('router-link',{attrs:{"to":{
                                    name: 'NewsDetail',
                                    params: { contentId: item.id },
                                }}},[_c('h2',[_vm._v(" "+_vm._s(item.title.rendered)+" ")])])],1),_c('div',{staticClass:"co__date"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date,"yyyy-MM-dd"))+" ")]),_c('div',{staticClass:"co__desc",domProps:{"innerHTML":_vm._s(item.excerpt.rendered)}})])])}),0)]),_c('div',{staticClass:"news-block__load-more"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadMoreStatus),expression:"loadMoreStatus"}],staticClass:"cb-primary",on:{"click":_vm.loadMore}},[_vm._v("加载更多")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loadMoreStatus),expression:"!loadMoreStatus"}],staticClass:"no-more"},[_vm._v("没有更多了~")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }