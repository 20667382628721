<template>
    <div class="banner">
        <el-carousel
            class="banner-carousel"
            direction="vertical"
            :autoplay="false"
            ref="bannerCarousel"
            @change="carousel_chage"
        >
            <el-carousel-item
                class="banner-carousel__item"
                :class="{ 'dark-model': item.dark_model }"
                v-for="item in banner"
                :key="item.id"
                :style="{ 'background-image': 'url(' + item.image + ')' }"
            >
                <div class="banner-content">
                    <div class="banner-content__other">
                        <div class="text">{{ item.other }}</div>
                        <div class="bottom-line"></div>
                    </div>
                    <div class="banner-content__title">
                        <h2>{{ item.title }}</h2>
                    </div>
                    <div class="banner-content__sub-title">
                        {{ item.sub_title }}
                    </div>
                    <div class="banner-content__desc">
                        {{ item.desc }}
                    </div>
                    <div class="banner-content__action">
                        <a href="#" class="button-link">了解更多</a>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="banner-carousel__control">
            <div class="banner-carousel__buttons">
                <span
                    v-on:click="carousel_prev()"
                    class="banner-carousel__button prev"
                    ><v-icon
                        class="banner-carousel__button-icon"
                        name="bi-arrow-left-circle-fill"
                    ></v-icon
                ></span>
                <span class="banner-carousel__button-text">{{carousel_index}} / {{banner.length}}</span>
                <span
                    v-on:click="carousel_next()"
                    class="banner-carousel__button next"
                    ><v-icon
                        class="banner-carousel__button-icon"
                        name="bi-arrow-right-circle-fill"
                    ></v-icon
                ></span>
            </div>
            <div class="banner-carousel__precess">
                <span class="stock">
                    <transition :duration="1000">
                        <i></i>
                    </transition>
                </span>
                <!-- <span class="count-time">5</span> -->
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Carousel, CarouselItem } from "element-ui";
Vue.use(Carousel).use(CarouselItem);

// https://static.fun2ex.com/w1.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w2.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w3.jpg?imageMogr2/thumbnail/1920x
// https://static.fun2ex.com/w4.jpg?imageMogr2/thumbnail/1920x

export default {
    name: "HomeCarousel",
    data() {
        let self = this;
        return {
            banner: [
                {
                    id: 1,
                    title: "雄心永固 塑造未来",
                    sub_title: "",
                    other: "Since 1996 to the present",
                    desc: "努力为客户创造价值，我们坚持以客户为中心，提供有效服务，是我们工作 的方向和价值评价的标尺，成就客户就是成就我们自己。 为员工创造机会， 为股东创造利润，为社会创造财富，实现振兴民族工业的伟大理想。",
                    url: "https://fun2ex.com",
                    image: self.$publicPath + "/static/banner/home-1@2x.jpg",
                    dark_model: true,
                },
                {
                    id: 2,
                    title: "积极开拓新兴市场",
                    sub_title: "",
                    other: "Since 1996 to the present",
                    desc: "公司在长期的市场开拓中与经销商保持密切合作，拥有近500家稳定的经销商，依托强大的营销团队维护现有优质客户并开拓新兴市场。公司与经销商深度合作，为顺利开拓市场奠定了良好的基础。",
                    url: "https://fun2ex.com",
                    image: self.$publicPath + "/static/banner/home-2@2x.jpg",
                    dark_model: false,
                },
                {
                    id: 3,
                    title: "绿色、环保、节能、节水",
                    sub_title: "ISO9001和ISO14001认证",
                    other: "Since 1996 to the present",
                    desc: "获得了“农村建设重点推广产品”、“中国环境标志产品”、“新华节水认证产品”、“科技创新绿色节能产品”和“中国绿色环保建材产品”等荣誉。",
                    url: "https://fun2ex.com",
                    image: self.$publicPath + "/static/banner/home-3@2x.jpg",
                    dark_model: true,
                },
            ],
            carousel_index: 1,
        };
    },
    mounted() {
        this.carousel_count();
    },
    methods: {
        hello() {
            alert("ddd");
        },
        carousel_prev() {
            // prev() {
            // console.log(this.$refs)
            this.$refs.bannerCarousel.prev();
            // },
            // next() {
            // }
        },
        carousel_chage(newValue) {
            this.carousel_index = newValue + 1;
            // console.log(newValue, oldValue);
        },
        carousel_next() {
            this.$refs.bannerCarousel.next();
        },
        carousel_count() {},
    },
};
</script>
<style lang="scss">
.banner {
    position: relative;
    &-carousel {
        width: 100%;
        height: 980px;

        &__item {
            height: 980px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

        &__buttons {
            display: flex;
            align-items: center;
        }
        &__button {
            color: #1b70f0;
            background: #fff;
            border-radius: 40px;
            width: 40px;
            height: 40px;
            overflow: hidden;
            position: relative;
            cursor: pointer;

            &-text {
                padding: 0 10px;
                // color: rgba(204, 221, 237, 1);
                color: #999;
            }

            &-icon {
                width: 50px;
                height: 50px;
                left: 50%;
                top: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }

        &__control {
            position: absolute;
            width: 1364px;
            z-index: 2;
            bottom: 20%;
            left: 50%;
            transform: translate(-50%, 0);
            // transform:
        }

        &__precess {
            position: absolute;
            left: -100px;
            top: -500px;
            .stock {
                display: block;
                border-radius: 4px;
                height: 240px;
                width: 4px;
                background: rgba(0, 0, 0, 0.2);

                i {
                    display: block;
                    position: absolute;
                    width: 4px;
                    border-radius: 4px;
                    background: #fff;
                    height: 40%;
                }
            }
        }
    }

    &-content {
        margin: 0 auto;
        width: 1364px;
        padding-right: 770px;
        height: 200px;
        // background: rgba(0, 0, 0, 0.2);
        color: #000;
        padding-top: 260px;
        box-sizing: border-box;

        &__other {
            .text {
                font-size: 18px;
                font-weight: 600;
                text-transform: uppercase;
            }
            .bottom-line {
                width: 60px;
                height: 2px;
                margin-top: 10px;
                background: #000;
            }
        }

        &__title {
            font-size: 64px;
            padding: 25px 0;
        }

        &_desc {
            font-size: 16px;
            font-weight: normal;
            line-height: 1.8;
        }

        &__action {
            padding-top: 80px;
            .button-link {
                display: block;
                width: 180px;
                height: 60px;
                background: #1b70f0;
                border-radius: 30px;
                color: #fff;
                text-align: center;
                line-height: 60px;

                &:hover {
                    box-shadow: 0px 2px 14px 0px rgba(0, 85, 165, 0.2);
                    text-decoration: none;
                }
            }
        }
    }
    .dark-model {
        //   .banner-carousel {
        //       &__button {
        //           &__text {
        //               color: #000;
        //           }
        //       }
        //   }
        .banner-content {
            //   background: rgba(255, 255, 255, 0.2);
            color: #fff;

            .bottom-line {
                background: #fff;
            }
            &__action {
                .button-link {
                    box-sizing: border-box;
                    background: transparent;
                    border: 2px solid #fff;
                    text-align: center;
                    line-height: 60px;

                    &:hover {
                        box-shadow: 0px 2px 14px 0px rgba(0, 85, 165, 0.2);
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.mobile {
    .banner-carousel {
        height: 190px;

        &__item {
            height: 190px;
        }
    }
    .banner-carousel__control {
        display: none;
    }
    .banner-content {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0 auto;
        padding-top: 50px;
        padding-left: 30px;

        &__other .text {
            font-size: 12px;
        }
        &__title {
            font-size: 24px;
            padding: 15px 0;
        }
        &__sub-title,
        &__desc,
        &__action {
            display: none;
        }
    }
}
</style>
