<template>
    <div class="app-container page-iria" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/investor-relations.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block ir-block__top">
            <div class="page-breadcrumb width-master">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{
                        $route.meta.title
                    }}</el-breadcrumb-item>
                </el-breadcrumb>
                <InvestorRelationsNav></InvestorRelationsNav>
            </div>
        </div>

        <div class="page-block width-master">
            <div class="switch-tabs">
                <span
                    @click="stPanelShowOne = true"
                    :class="{ stActive: stPanelShowOne }"
                    class="st-1"
                    >投资者咨询</span
                >
                <span
                    @click="stPanelShowOne = false"
                    :class="{ stActive: !stPanelShowOne }"
                    class="st-2"
                    >投资者调研</span
                >
            </div>
        </div>

        <div class="page-block width-master" v-show="stPanelShowOne">
            <div class="cooperation-form">
                <el-form :model="contactForm" :rules="rules" ref="contactForm">
                    <div class="form-item__tips">
                        <span class="el-icon-warning"></span>
                        <span class="txt">注：带*为必填项</span>
                    </div>

                    <div class="form-item form-item__s1">
                        <label class="text" for="">合作方向</label>
                        <el-radio v-model="contactForm.type" label="4255"
                            >工程项目合作</el-radio
                        >
                        <el-radio v-model="contactForm.type" label="4256"
                            >经销合作</el-radio
                        >
                    </div>
                    <div class="form-item">
                        <el-form-item class="form-item__left" prop="name">
                            <label for="" class="text">* 姓名</label>
                            <el-input
                                class="form-item__input"
                                placeholder="请输入您的姓名"
                                v-model="contactForm.name"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item class="form-item__right" prop="phone">
                            <label for="" class="text">* 电话</label>
                            <el-input
                                class="form-item__input"
                                placeholder="请输入您的联系电话"
                                v-model="contactForm.phone"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item class="form-item__left" prop="mail">
                            <label for="" class="text">邮箱</label>
                            <el-input
                                class="form-item__input"
                                placeholder="请输入您的联系邮箱"
                                v-model="contactForm.mail"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item class="form-item__right" prop="fax">
                            <label for="" class="text">传真</label>
                            <el-input
                                class="form-item__input"
                                placeholder="请输入您的传真号"
                                v-model="contactForm.fax"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item class="form-item__full" prop="company">
                            <label for="" class="text">* 公司名称</label>
                            <el-input
                                class="form-item__input"
                                placeholder="请输入您的公司名称"
                                v-model="contactForm.company"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item class="form-item__full" prop="site">
                            <label for="" class="text">公司网站</label>
                            <el-input
                                class="form-item__input"
                                placeholder="请输入您的公司网站链接"
                                v-model="contactForm.site"
                                clearable
                            >
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item class="form-item__full" prop="address">
                            <label for="" class="text">* 公司地址</label>
                            <el-input
                                class="form-item__input form-item__area-mobile"
                                placeholder="请选择所在区域"
                                v-model="areaTemp.vant"
                                suffix-icon="el-icon-caret-bottom"
                                @focus="onSelect()"
                            >
                            </el-input>
                            <div class="layout-flex__align">
                                <el-cascader
                                    size="large"
                                    class="form-item__area-pc form-item__input"
                                    :options="options"
                                    v-model="areaTemp.el"
                                    @change="onElementAreaFinish"
                                >
                                </el-cascader>
                                <el-input
                                    class="form-item__input"
                                    placeholder="请输入剩余地址信息"
                                    v-model="contactForm.address"
                                    clearable
                                >
                                </el-input>
                            </div>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item class="form-item__full" prop="content">
                            <label for="" class="text">* 明确需求</label>
                            <el-input
                                class="form-item__input"
                                placeholder="请填写您的需求信息"
                                v-model="contactForm.content"
                                type="textarea"
                            >
                            </el-input>
                        </el-form-item>
                    </div>
                    <div class="form-item form-item__upload">
                        <div class="inside">
                            <span class="el-icon-upload"></span>
                            <span>点击上传文件（图片/PDF/Office 文件）</span>
                            <input
                                class="el-upload"
                                type="file"
                                @change="getFiles($event)"
                            />
                        </div>
                        <div class="select-file" v-show="uploadFileName">
                            <el-alert
                                :title="'已选择：' + uploadFileName"
                                :closable="false"
                                type="success"
                            >
                            </el-alert>
                        </div>
                    </div>
                    <div class="form-action">
                        <el-button
                            type="primary"
                            @click="onSubmit()"
                            class="cb-primary"
                            :loading="button_status.loading"
                            >{{
                                button_status.loading ? "加载中" : "保存信息"
                            }}</el-button
                        >
                        <!-- <span @click="saveToCF()" class="cb-primary">联系我们</span> -->
                        <div class="info">
                            我们将会在您提交信息后 24 小时内答复
                        </div>
                    </div>
                </el-form>

                <van-popup v-model="show" round position="bottom">
                    <van-cascader
                        v-model="cascaderValue"
                        title="请选择所在地区"
                        :options="options"
                        active-color="#1989fa"
                        @close="show = false"
                        @finish="onVantAreaFinish"
                        :field-names="fieldNames"
                    />
                </van-popup>
            </div>
        </div>
        <div class="page-block width-master" v-show="!stPanelShowOne">
            <div class="ir-block__hd ir-block__list">
                <div
                    class="list-item"
                    v-for="(item, index) in list"
                    :key="index"
                >
                    <div class="text">
                        <a target="_blank" :href="item.acf.file">
                            {{item.title.rendered}}
                        </a>
                    </div>
                    <span class="date">{{ item.date | formatDate("yyyy-MM-dd") }}</span>
                </div>
            </div>
            <div class="page-pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pagination.currentPage"
                    layout="total, prev, pager, next"
                    :page-size="pagination.pageSize"
                    :total.sync="pagination.total"
                    background
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import {
    Breadcrumb,
    BreadcrumbItem,
    Radio,
    Input,
    Button,
    Form,
    FormItem,
    Cascader,
    Alert,
} from "element-ui";

// 移动端
import { Cascader as vantCascader, Popup } from "vant";
import InvestorRelationsNav from "@/components/InvestorRelationsNav.vue";

// import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
import { regionData, CodeToText } from "element-china-area-data";
import cf7 from "@/api/contact-form-7";
import apiWpV2 from "@/api/wp";

Vue.use(Breadcrumb)
    .use(BreadcrumbItem)
    .use(Radio)
    .use(Input)
    .use(Button)
    .use(Form)
    .use(FormItem)
    .use(Cascader)
    .use(Alert);

Vue.use(vantCascader).use(Popup);

export default {
    name: "IRInteractive",
    components: {
        InvestorRelationsNav,
    },
    data() {
        let ValidateMail = (rule, value, callback) => {
            if (!rule.required && !value) {
                callback();
                return true;
            }
            if (value === "") {
                callback(new Error("请输入您的联系邮箱"));
            } else {
                // 检查是否符合邮件格式
                let isMail =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        value
                    );

                if (isMail) {
                    // 需要保存到后台的格式
                    // this.contactForm.contactType = isMail ? 2 : 1
                    callback();
                } else {
                    callback(new Error("邮箱格式错误，请输入正确的邮件地址"));
                }
            }
        };

        let ValidatePhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入您的联系号码"));
            } else {
                // 手机
                let isPhone =
                    /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/.test(
                        value
                    );

                // 固定电话
                let isTel = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/.test(value);

                if (isPhone || isTel) {
                    // 需要保存到后台的格式
                    // this.contactForm.contactType = isMail ? 2 : 1
                    callback();
                } else {
                    callback(
                        new Error("号码格式错误，请输入正确的手机或者座机号码")
                    );
                }
            }
        };
        return {
            name: "Cooperation",
            // https://uat.xiongsu.cn/wp-json/wp/v2/investor_reception?per_page=20
            list: [],
            contactForm: {
                type: "4255",
                name: "",
                phone: "",
                mail: "",
                fax: "",
                company: "",
                site: "",
                province: "", // 省
                county: "", // 市/县
                district: "", // 区
                address: "",
                content: "",
                attachment: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入联系人姓名",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        validator: ValidatePhone,
                        trigger: "blur",
                    },
                ],
                company: [
                    {
                        required: true,
                        message: "请输入公司名称",
                        trigger: "blur",
                    },
                ],
                mail: [
                    {
                        required: false,
                        validator: ValidateMail,
                        trigger: "blur",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "请填写您的地址",
                        trigger: "blur",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "请描述一下您的需求",
                        trigger: "blur",
                    },
                ],
            },
            button_status: {
                loading: false,
            },
            areaTemp: {
                el: "",
                vant: "",
            },
            uploadFileName: "", // 上传文件名
            show: false,
            fieldValue: "",
            cascaderValue: "",
            // 选项列表，children 代表子选项，支持多级嵌套
            options: regionData,
            fieldNames: { text: "label", value: "value", children: "children" },
            stPanelShowOne: true,
            pagination: {
                currentPage: 1,
                pageSize: 15,
                total: 0,
                totalPages: 0
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        // 滚动条定位到第一个校验失败的div的位置
        locateToErr() {
            setTimeout(() => {
                const errorDiv = document.querySelectorAll(".is-error");
                errorDiv[0].scrollIntoView();
            }, 0);
        },
        // 全部选项选择完毕后，会触发 finish 事件
        onVantAreaFinish({ selectedOptions }) {
            this.show = false;
            // console.log(selectedOptions);
            // 处理显示信息
            this.areaTemp.vant = selectedOptions
                .map((option) => option.label)
                .join("/");

            this.contactForm.province = selectedOptions[0].label;
            this.contactForm.county = selectedOptions[1].label;
            this.contactForm.district = selectedOptions[2].label;
            // console.log(this.areaTemp.vant);
        },
        onElementAreaFinish(value) {
            this.contactForm.province = CodeToText[value[0]];
            this.contactForm.county = CodeToText[value[1]];
            this.contactForm.district = CodeToText[value[2]];
        },
        onSelect() {
            this.show = true;
        },
        getFiles(e) {
            console.log(e.target.files[0]);
            this.uploadFileName = e.target.files[0].name;
            this.contactForm.attachment = e.target.files[0];
        },

        onSubmit() {
            let self = this;
            // console.log("no name");
            self.$refs.contactForm.validate((valid) => {
                if (valid) {
                    self.saveToCF();
                } else {
                    self.locateToErr();
                    return false;
                }
                // if (valid) {
                // // alert('submit!')
                // self.saveContact(self.contactForm)
                // } else {
                // if (Object.entries(obj).length === 1 && obj.hasOwnProperty('agree')) {
                //     self.$message({
                //     message: '必须要确认隐私协议后方可继续',
                //     type: 'warning'
                //     })
                // }
                // return false
                // }
            });
        },
        async saveToCF() {
            let self = this;
            // 标志状态
            if (self.button_status.loading) return;

            self.button_status.loading = true;
            let rep = await cf7.SaveToCFfeedback(this.contactForm);
            self.button_status.loading = false;

            if (rep.data.status === "mail_sent") {
                // 提示成功
                self.$msgbox({
                    message:
                        "感谢您的投递，我们会在<br />获得信息第一时间与您联系",
                    dangerouslyUseHTMLString: true,
                    customClass: "cus-class__messagebox",
                    showConfirmButton: true,
                    confirmButtonText: "返回首页",
                    closeOnClickModal: false,
                    center: true,
                    showClose: true,
                    callback: function () {
                        window.location.href = "https://www.xiongsu.cn";
                    },
                });
            } else {
                let msg = rep.data.message;
                if (rep.data.invalid_fields.length) {
                    msg = `${msg} - ${rep.data.invalid_fields[0].message}`;
                }
                self.$message({
                    message: msg,
                    type: "warning",
                });
                // 提示失败
            }
        },
        setPagination(headers) {
            this.pagination.total = headers["x-wp-total"] * 1;
            this.pagination.totalPages = headers["x-wp-totalpages"] * 1;
        },
        async getInvestorReception(config) {
            const res = await apiWpV2.getInvestorReception(config);
            this.setPagination(res.headers);
            this.list = res.data;
            // 回到首页
            window.scrollTo(0, 0);
        },
        init() {
            this.getInvestorReception({
                page: 1,
                per_page: 15
            });
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.getInvestorReception({
                page: val,
                per_page: 15
            });
        },
    },
};
</script>
<style lang="scss">
@import "~@/assets/style/investor-relations.scss";
.page-iria {
    .cooperation-form {
        padding-top: 30px;
    }
    .ir-block__hd {
        padding-top: 0;
    }
}
</style>
