import ajax from "@/libs/ajax";

let defaultArgs = {
    // 每页数目
    per_page: 10,
    // 默认分页
    page: 1,
};

const baseURL = "/wp/v2";
const apiWpV2 = {
    // getPageSetting
    getPageSetting: (params) => {
        return ajax.request({
            url: `${baseURL}/page_setting/${params.id}`,
            method: "get",
        });
    },
    // 文章列表
    getPosts: (params) =>
        ajax.request({
            url: `${baseURL}/posts`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    getPostById: (params) =>
        ajax.request({
            url: `${baseURL}/posts/${params.id}`,
            method: "GET",
        }),
    // 获取评论
    getComments: (params) =>
        ajax.request({
            url: `${baseURL}/comments`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 公司治理
    getCure: (params) =>
        ajax.request({
            url: `${baseURL}/cure`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 公平在身边
    getFair: (params) =>
        ajax.request({
            url: `${baseURL}/fair`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 信息披露
    getInfoReveal: (params) =>
        ajax.request({
            url: `${baseURL}/info_reveal`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 信息披露临时
    getInfoRevealTemp: (params) =>
        ajax.request({
            url: `${baseURL}/info_reveal_temp`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 投资者接待
    getInvestorReception: (params) =>
        ajax.request({
            url: `${baseURL}/investor_reception`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 投资者保护
    getProtection: (params) =>
        ajax.request({
            url: `${baseURL}/protection`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 股东回报
    getStockHolder: (params) =>
        ajax.request({
            url: `${baseURL}/stock-holder`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 雄塑历史
    getXiongsuHistory: (params) =>
        ajax.request({
            url: `${baseURL}/xiongsu_history`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 雄塑公益列表
    getPublicWelfare: (params) =>
        ajax.request({
            url: `${baseURL}/public_welfare`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 集团荣誉
    getGroupHonor: (params) =>
        ajax.request({
            url: `${baseURL}/group_honor`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 产品 index
    getProductIndex: (params) =>
        ajax.request({
            url: `${baseURL}/products`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 产品分类
    getProductCategory: (params) =>
        ajax.request({
            url: `${baseURL}/product_category`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
    // 产品详情
    // https://uat.xiongsu.cn/rest-api/docs/#/product_detail/get_wp_v2_product_detail
    getProductDetail: (params) =>
        ajax.request({
            url: `${baseURL}/product_detail/${params.id}`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),

    // product_color
    // https://uat.xiongsu.cn/rest-api/docs/#/product_color/get_wp_v2_product_color
    getProductColor: (params) =>
        ajax.request({
            url: `${baseURL}/product_color`,
            params: Object.assign({}, {
                per_page: 100,
                page: 1
            }, params),
            method: "GET",
        }),
    
    /// https://uat.xiongsu.cn/rest-api/docs/#/product_detail_setting/get_wp_v2_product_detail_setting
    getProductDetailSetting: (params) =>
        ajax.request({
            url: `${baseURL}/product_detail_setting/${params.id}`,
            method: "GET",
        }),
    
    // https://uat.xiongsu.cn/rest-api/docs/#/production_base_list/get_wp_v2_production_base_list
    getProductionBaseList: (params) =>
        ajax.request({
            url: `${baseURL}/production_base_list`,
            params: Object.assign({}, defaultArgs, params),
            method: "GET",
        }),
};

export default apiWpV2;
