<template>
    <div class="footer">
        <div class="inside">
            <Logo class="footer-logo" />
            <div class="links">
                <div class="title">快速链接</div>
                <div class="links-items">
                    <router-link class="links-item" :to="{ name: 'NewsList' }">
                        新闻资讯
                    </router-link>
                    <router-link class="links-item" :to="{ name: 'Products' }">
                        雄塑产品
                    </router-link>
                    <router-link
                        class="links-item"
                        :to="{ name: 'InvestorRelations' }"
                    >
                        投资者关系
                    </router-link>
                    <router-link class="links-item" :to="{ name: 'AboutUS' }">
                        关于雄塑
                    </router-link>
                    <router-link class="links-item" :to="{ name: 'JoinUS' }">
                        加入我们
                    </router-link>
                    <router-link
                        class="links-item"
                        :to="{ name: 'Cooperation' }"
                    >
                        合作服务
                    </router-link>
                </div>
            </div>

            <div class="contact">
                <div class="title">联系我们</div>
                <div class="contact-items">
                    <div class="contact-item contact-item__address">
                        <!-- <v-icon
              class="contact-item__icon"
              name="co-location-pin"
              scale="1.2"
            ></v-icon>
            <span>广东省佛山市南海区九江镇龙高路雄塑工业园</span> -->

                        <div class="contact-item__icon">
                            <v-icon
                                fill="#999999"
                                name="co-location-pin"
                            ></v-icon>
                        </div>
                        <div class="contact-item__text">
                            广东省佛山市南海区九江镇龙高路雄塑工业园
                        </div>
                    </div>
                    <div class="contact-item contact-item__phone">
                        <div class="contact-item__icon">
                            <v-icon
                                scale="0.8"
                                fill="#666666"
                                name="bi-telephone"
                            ></v-icon>
                        </div>
                        <div class="contact-item__text">
                            <a href="tel:400-833-2888">400-833-2888（国内）</a>
                            /
                            <a href="tel:+8613726614086">
                                +86 13726614086（海外专线）
                            </a>
                        </div>
                    </div>
                    <div class="contact-item contact-item__mail">
                        <div class="contact-item__icon">
                            <v-icon
                                scale="0.85"
                                fill="#888888"
                                name="hi-mail"
                            ></v-icon>
                        </div>
                        <div class="contact-item__text">gdxs@xiongsu.cn</div>
                    </div>
                </div>
            </div>

            <div class="social-media">
                <div class="title">关注我们</div>
                <div class="social-media__list">
                    <div class="nomarl">
                        <a class="social-media__item" href="#">
                            <v-icon
                                class="social-media__icon"
                                name="fa-weibo"
                            ></v-icon>
                        </a>
                        <a class="social-media__item" href="#">
                            <v-icon
                                class="social-media__icon"
                                name="fa-twitter"
                            ></v-icon>
                        </a>
                        <a class="social-media__item" href="#">
                            <v-icon
                                class="social-media__icon"
                                name="fa-facebook-square"
                            ></v-icon>
                        </a>
                        <a class="social-media__item" href="#">
                            <v-icon
                                class="social-media__icon"
                                name="fa-linkedin-in"
                            ></v-icon>
                        </a>
                    </div>
                    <div class="qrcode">
                        <div class="qrcode-image">
                            <img
                                src="https://static.fun2ex.com/qrcode_HOMSOTECH_1.jpg"
                                alt=""
                            />
                        </div>
                        <div class="qrcode-intro">关注雄塑公众号</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="other-info">
            <div class="beian"><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备12088529号-1</a></div>
            <div class="copyright">
                &#169; {{year}} XIONGSU ALL RIGHTS PESERVED
            </div>
        </div>
    </div>
</template>
<script>
import Logo from "@/components/Logo.vue";

export default {
    name: "Footer",
    components: {
        Logo,
    },
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
};
</script>
<style lang="scss">
.footer {
    box-sizing: border-box;
    .inside {
        width: 1520px;
        border-top: 1px solid #d3d5dc;
        padding: 60px;
        margin: 0 auto;
        display: flex;
        box-sizing: border-box;
    }
    .qrcode {
        width: 100px;

        &-image {
            border: 1px solid #ccc;
            img {
                display: block;
                width: 100%;
            }
        }

        &-intro {
            padding-top: 8px;
            text-align: center;
            font-size: 14px;
            color: #666;
        }
    }

    .title {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 10px;
    }

    .links {
        padding-left: 120px;
        &-item {
            display: block;
            color: #666;
            font-size: 14px;
            padding: 7px 0;
        }
    }
    .contact {
        padding-left: 120px;
        font-size: 14px;
        &-item {
            color: #666;
            display: flex;
            height: 30px;
            align-items: center;

            a {
                color: #666;
                &:hover {
                    text-decoration: none;
                }
            }

            &__icon {
                width: 26px;
                text-align: center;
                padding-right: 6px;
            }
        }
    }

    .social-media {
        margin-left: auto;
        .nomarl {
            display: flex;
            padding-bottom: 20px;
        }
        &__item {
            display: flex;
            align-items: center;
            justify-items: center;
            width: 20px;
            height: 20px;
            padding-right: 20px;
        }
    }

    .other-info {
        padding: 10px 0 20px;
        text-align: center;
        font-size: 14px;

        .copyright {
            padding-top: 8px;
            color: #999;
            font-size: 12px;
        }
    }
}

.mobile {
    .footer {
        .inside {
            width: 100vw;
            padding: 20px;
            display: block;
        }

        .footer-logo,
        .links,
        .contact,
        .social-media {
            padding: 0;
            margin: 0px;
            font-size: 10px;
        }

        .title {
            font-size: 12px;
            padding-top: 15px;
        }
        .links-item {
            font-size: 10px;
            padding: 4px 0;
        }
        .contact-item {
            height: 20px;
            &__icon {
                width: 12px;
            }
        }
        .social-media {
            &__item {
                padding: 0;
            }
            .nomarl {
                padding-bottom: 10px;
                justify-content: space-between;
                width: 40vw;
                margin: 0 auto;
            }
            .qrcode {
                margin: 0 auto;

                &-intro {
                    font-size: 10px;
                }
            }
        }
        .other-info {
            font-size: 10px;
            .copyright {
                font-size: 8px;
                padding-top: 5px;
            }
        }
    }
}
</style>
