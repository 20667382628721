<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/about-production-base@2x.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block ir-block__top">
            <div class="page-breadcrumb width-master">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>生产基地</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>

        <div class="page-block width-master">
            <div class="pb-intro-text">
                <p><span class="sp-text">6</span> 家全资子公司</p>
                <p>
                    拥有广东南海、广西南宁、河南新乡、江西宜春、海南海口、云南玉溪六大生产基地
                </p>
            </div>
            <div class="pb-intro-maps">
                <img :src="`${$publicPath}/static/other/maps.jpg`" alt="" />
            </div>
            <div class="pb-group">
                <div
                    v-for="item in pbList"
                    class="pb-group__item"
                    :key="item.id"
                >
                    <div class="left">
                        <img
                            :src="
                                item.acf.image
                                    ? item.acf.image
                                    : `${$publicPath}/static/other/pb-img.jpg`
                            "
                            :alt="item.title.rendered"
                        />
                    </div>
                    <div class="right">
                        <div class="pb-group__info">
                            <div class="sub-title">
                                {{ item.acf.en_title }}
                            </div>
                            <div class="title">
                                {{ item.title.rendered }}
                            </div>
                            <div class="contact-items">
                                <div class="contact-item contact-item__address">
                                    <div class="contact-item__icon">
                                        <v-icon
                                            fill="#999999"
                                            name="co-location-pin"
                                        ></v-icon>
                                    </div>
                                    <div class="contact-item__text">
                                        地址：{{ item.acf.addr }}
                                    </div>
                                </div>
                                <div
                                    class="contact-item contact-item__postcode"
                                >
                                    <div class="contact-item__icon">
                                        <v-icon
                                            scale="0.8"
                                            fill="#666666"
                                            name="bi-telephone"
                                        ></v-icon>
                                    </div>
                                    <div class="contact-item__text">
                                        邮编：{{ item.acf.post_code }}
                                    </div>
                                </div>
                                <div class="contact-item contact-item__phone">
                                    <div class="contact-item__icon">
                                        <v-icon
                                            scale="0.8"
                                            fill="#666666"
                                            name="bi-telephone"
                                        ></v-icon>
                                    </div>
                                    <div class="contact-item__text">
                                        电话：{{ item.acf.phone }}
                                    </div>
                                </div>
                                <div class="contact-item contact-item__fax">
                                    <div class="contact-item__icon">
                                        <v-icon
                                            scale="0.8"
                                            fill="#666666"
                                            name="bi-telephone"
                                        ></v-icon>
                                    </div>
                                    <div class="contact-item__text">
                                        传真：{{ item.acf.fax }}
                                    </div>
                                </div>
                                <div class="contact-item contact-item__site">
                                    <div class="contact-item__icon">
                                        <v-icon
                                            scale="0.85"
                                            fill="#888888"
                                            name="hi-mail"
                                        ></v-icon>
                                    </div>
                                    <div class="contact-item__text">
                                        网址：{{ item.acf.site_url }}
                                    </div>
                                </div>
                                <div class="contact-item contact-item__mail">
                                    <div class="contact-item__icon">
                                        <v-icon
                                            scale="0.85"
                                            fill="#888888"
                                            name="hi-mail"
                                        ></v-icon>
                                    </div>
                                    <div class="contact-item__text">
                                        邮箱：{{ item.acf.mail }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem } from "element-ui";
import apiWpV2 from "@/api/wp";

Vue.use(Breadcrumb).use(BreadcrumbItem);

export default {
    name: "ProductionBase",
    components: {},
    data() {
        return {
            list: [],
            pbList: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async getProductionBaseList(config) {
            const res = await apiWpV2.getProductionBaseList(config);
            this.pbList = res.data;
            // 回到首页
            window.scrollTo(0, 0);
        },
        init() {
            this.getProductionBaseList({
                page: 1,
                per_page: 50,
            });
        },
    },
};
</script>
<style lang="scss">
.pb {
    &-intro-text {
        font-size: 18px;
        line-height: 1.6;

        .sp-text {
            font-size: 60px;
            color: #1b70f0;
        }
    }

    &-intro-maps {
        img {
            display: block;
            width: 100%;
            height: auto;
            border: 0 none;
        }
    }

    &-group {
        &__item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: #f5f5f5;
            margin-bottom: 30px;

            .left {
                overflow: hidden;
                width: 866px;
                flex: 0 0 866px;
                img {
                    display: block;
                     width: 866px;
                height: 560px;
                    border: 0 none;
                    object-fit: cover;
                }
            }
            .right {
            }
        }

        &__info {
            // width: 460px;
            padding-left: 100px;

            .sub-title {
                font-size: 16px;
                padding-bottom: 10px;
            }
            .title {
                font-size: 24px;
                padding-bottom: 24px;
            }
        }
        .contact {
            padding-left: 120px;
            font-size: 14px;
            &-item {
                color: #666;
                display: flex;
                height: 30px;
                align-items: center;

                &__icon {
                    width: 26px;
                    text-align: center;
                    padding-right: 6px;
                }
            }
        }
    }
}
.mobile {
    .pb {
        &-intro {
            &-text {
                font-size: 12px;
                color: #666;

                width: 90vw;
                margin: 0 auto;
                padding-bottom: 5vw;

                .sp-text {
                    font-size: 12px;
                    color: #666;
                }
            }
        }
        &-group {
            margin-top: 10vw;
        }
        &-group__item {
            width: 90vw;
            margin: 0 auto;
            display: block;
            padding: 5vw 0;
            background: #fff;
            border-top: 1px solid #ccc;
            .left {
                display: none;
            }
        }
        &-group__info {
            padding: 0;

            .sub-title {
                font-size: 10px;
                color: #999;
                padding-bottom: 1vw;
            }
            .title {
                font-weight: bold;
                font-size: 13px;
                padding-bottom: 3vw;
            }
            .contact-items {
                font-size: 11px;

                .contact-item {
                    height: 8vw;
                }
            }
        }
    }
}
</style>
