<template>
    <div class="app-container" :class="$route.meta.class">
        <div
            class="page-banner"
            :style="`background-image: url(${$publicPath}/static/banner/investor-relations.jpg);`"
        >
            <div class="page-banner__inside width-master">
                <div class="page-banner__title">{{ $route.meta.en_title }}</div>
                <div class="page-banner__subtitle">{{ $route.meta.title }}</div>
                <div class="page-banner__desc">
                    {{ $route.meta.desc }}
                </div>
            </div>
        </div>

        <div class="page-block ir-block__top">
            <div class="page-breadcrumb width-master">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        >首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item>{{
                        $route.meta.title
                    }}</el-breadcrumb-item>
                </el-breadcrumb>
                <InvestorRelationsNav></InvestorRelationsNav>
            </div>
        </div>

        <div class="page-block width-master">
            <div class="ir-block__hd ir-block__collapse">
                <el-collapse v-model="activeName">
                    <el-collapse-item
                        v-for="item in list.slice().reverse()"
                        :key="item.acf.order"
                        :title="item.title.rendered"
                        :name="item.acf.order"
                    >
                        <div
                            class="content-detail"
                            v-html="item.content.rendered"
                        ></div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { Breadcrumb, BreadcrumbItem } from "element-ui";
import InvestorRelationsNav from "@/components/InvestorRelationsNav.vue";
import apiWpV2 from "@/api/wp";

Vue.use(Breadcrumb).use(BreadcrumbItem);

export default {
    name: "IRStockholder",
    components: {
        InvestorRelationsNav,
    },
    data() {
        return {
            list: [],
            activeName: "1",
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async getStockHolder() {
            const res = await apiWpV2.getStockHolder({
                per_page: 15,
            });
            this.list = res.data;
        },
        init() {
            this.getStockHolder();
        },
    },
};
</script>
<style lang="scss">
@import "~@/assets/style/investor-relations.scss";

.ir-block {
    &__collapse {
        .el-collapse {
            border: 0 none;
        }
        .el-collapse-item__header {
            font-size: 24px;
            height: auto;
            line-height: 1;
            padding: 30px 0;
            display: block;
            position: relative;
            padding-left: 36px;
        }

        .el-collapse-item__arrow {
            position: absolute;
            left: 0;
            top: auto;
        }

        .content-detail {
            font-size: 14px;
            line-height: 2;
        }

        .base-table {
            width: 80%;
            margin: 0 auto;
            border: 1px solid #1b70f0;
            border-radius: 10px;
            border-collapse: separate;
            border-spacing: 0;
            text-align: center;
            overflow: hidden;

            th {
                background: #1b70f0;
                color: #fff;
                padding: 15px 0;
            }

            tr {
                td {
                    border-top: 1px solid #1b70f0;
                    padding: 15px 0;
                }
                &:first-child {
                    td {
                        border-top: 0 none;
                    }
                }
            }
        }
    }
}
</style>
