<template>
    <div class="header" :class="{ 'home-header': isHomePage }">
        <div class="header-inside">
        <div class="header__left">
            <div class="mobile-menu" @click="doDDD()">
                <v-icon scale="1.5" name="md-menu"></v-icon>
            </div>

            <Logo
                class="header-logo"
                :class="{ 'header-logo__white': isHomePage }"
            />
            <!-- /.logo-block -->
            <div class="navigation">
                <div
                    v-for="navFater in navigation"
                    :key="navFater.name"
                    class="navigation-item"
                >
                    <router-link :to="{ name: navFater.name }">{{
                        navFater.text
                    }}</router-link>
                    <div
                        class="navigation-child__block"
                        v-if="navFater.children.length"
                    >
                        <router-link
                            v-for="navChild in navFater.children"
                            :key="navChild.name"
                            class="navigation-child"
                            :to="{ name: navChild.name }"
                            >{{ navChild.text }}</router-link
                        >
                    </div>
                </div>
                <div class="navigation-item">
                    <a target="_balnk" href="https://xiongsu.en.alibaba.com/">阿里国际站</a>
                </div>
            </div>
            <!-- /.navigation -->
        </div>

        <div class="header__right">
            <div class="search">
                <i class="el-icon-search"></i>
                <input
                    class="search-input"
                    v-model="modelSearch"
                    type="text"
                    placeholder="请输入内容"
                />
            </div>

            <div class="lang">
                <a title="切换到英文站点" href="https://www.homsopipe.com">英文</a>
            </div>
        </div>

        <el-drawer
            class="navigation-drawer"
            title=" "
            :visible.sync="drawer"
            size="100%"
            :direction="direction"
            custom-class="cus_navigation-drawer"
        >
            <div class="navigation-mobile">
                <div
                    v-for="navFater in navigation"
                    :key="navFater.name"
                    class="navigation-item"
                >
                    <router-link :to="{ name: navFater.name }">{{
                        navFater.text
                    }}</router-link>
                    <div
                        class="navigation-child__block"
                        v-if="navFater.children.length"
                    >
                        <router-link
                            v-for="navChild in navFater.children"
                            :key="navChild.name"
                            class="navigation-child"
                            :to="{ name: navChild.name }"
                            >{{ navChild.text }}</router-link
                        >
                    </div>
                </div>
                <div class="navigation-item">
                    <a target="_balnk" href="https://xiongsu.en.alibaba.com/">阿里国际站</a>
                </div>
            </div>
        </el-drawer>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
// import {
//   Dropdown,
//   DropdownMenu,
//   DropdownItem,
//   Menu,
//   Submenu,
//   MenuItem,
//   MenuItemGroup,
// } from "element-ui";

import { Drawer } from "element-ui";
import device from "current-device";

// Vue.use(Dropdown);
// Vue.use(DropdownMenu);
// Vue.use(DropdownItem);
// Vue.use(Menu);
// Vue.use(Submenu);
// Vue.use(MenuItem);
Vue.use(Drawer);
import Logo from "@/components/Logo.vue";

export default {
    name: "componets-header",
    props: {},
    components: {
        Logo,
    },
    data() {
        return {
            activeIndex: "1",
            modelSearch: "",
            drawer: false,
            direction: "ttb",
            navigation: [
                {
                    text: "首页",
                    name: "Home",
                    children: [],
                },
                {
                    text: "新闻资讯",
                    name: "NewsList",
                    children: [],
                },
                {
                    text: "雄塑产品",
                    name: "Products",
                    children: [
                        {
                            text: "工程案例",
                            name: "ProjectList",
                        },
                    ],
                },
                {
                    text: "投资者关系",
                    name: "InvestorRelations",
                    children: [
                        {
                            text: "证券概括",
                            name: "IRSecurities",
                        },
                        {
                            text: "公司治理",
                            name: "IRCompany",
                        },
                        {
                            text: "股东回报",
                            name: "IRStockholder",
                        },
                        {
                            text: "信息披露",
                            name: "IRInfomation",
                        },
                        {
                            text: "投资者互动",
                            name: "IRInteractive",
                        },
                        {
                            text: "投资者保护",
                            name: "IRProtect",
                        },
                    ],
                },
                {
                    text: "关于雄塑",
                    name: "AboutUS",
                    children: [
                        {
                            text: "集团介绍",
                            name: "AboutUS",
                        },
                        {
                            text: "生产基地",
                            name: "ProductionBase",
                        },
                        {
                            text: "企业文化",
                            name: "CompanyCulture",
                        },
                        {
                            text: "雄塑公益",
                            name: "PublicWelfare",
                        },
                    ],
                },
                {
                    text: "加入我们",
                    name: "JoinUS",
                    children: [],
                },
                {
                    text: "合作服务",
                    name: "Cooperation",
                    children: [],
                },
            ],
            isHomePage: false,
        };
    },
    mounted() {
        this.isHomePage = this.$route.name === "Home";
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        doDDD() {
            console.log("no xxx");
            this.drawer = true;
        },
    },
    watch: {
        $route(to, from) {
            // 监听路由是否变化
            // console.info('isHome', to.name === 'Home')
            let self = this;
            self.isHomePage = to.name === "Home";
            if (to.name != from.name && device.mobile()) {
                self.drawer = false;
            }
        },
    },
};
</script>
<style lang="scss">
.header {
    
    // background-color: gray;
    
    
    position: relative;
    z-index: 10;

    .header-inside {
        position: relative;
        max-width: 1650px;
        margin: 0 auto;
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        height: 90px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }

    &__left {
        display: flex;
    }

    &__right {
        display: flex;
    }

    .navigation {
        display: flex;

        &-item {
            padding: 0 20px;
            position: relative;

            &:hover {
                .navigation-child__block {
                    display: block;
                }
            }
        }

        &-child {
            display: block;
            padding: 10px;
            &__block {
                display: none;
                position: absolute;
                background: #fff;
                padding: 20px;
                box-sizing: border-box;
                width: 144px;
                left: 50%;
                top: 39px;
                transform: translate(-50%, 0);
                text-align: center;
            }
        }
    }

    .search {
        width: 300px;
        box-sizing: border-box;
        height: 40px;
        border: 1px solid #ccc;
        padding-right: 20px;
        border-radius: 25px;
        background: #fff;
        display: flex;

        &-input {
            flex: 1;
            border: 0 none;
            outline: none;
            color: #999;
            font-size: 16px;
        }

        .el-icon-search {
            font-size: 20px;
            color: #999;
            height: 38px;
            line-height: 38px;
            text-align: center;
            width: 38px;
        }
    }

    .lang {
        margin-left: 30px;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        background: #1b70f0;
        color: #fff;
        font-size: 14px;
        border-radius: 50px;
        cursor: pointer;
    }
}
.home-header {
    margin-bottom: -120px;
    background: rgba(0, 0, 0, 0.1);

    a {
        color: #fff;
        &:hover {
            color: #fff;
            font-weight: bold;
            text-decoration: none;
        }
    }
    .navigation-child__block {
        background: rgba(255, 255, 255, 0.7);
    }
    .navigation-child {
        color: #333;
        &:hover {
            color: #333;
            font-weight: bold;
            text-decoration: none;
        }
    }
}

.mobile-menu {
    display: none;
}
.mobile {
    .search {
        display: none;
    }
    .lang {
        margin: 0;
        position: absolute;
        right: 10px;
        top: 6px;
        width: 30px;
        height: 30px;
        font-size: 10px;
        line-height: 30px;
    }
    .mobile-menu {
        display: block;
    }
    .navigation {
        display: none;
        &-mobile {
            padding: 0 30px;
            font-size: 15px;

            .navigation-item {
                // height: 10vw;
                // font-size:
                padding: 12px 0;
                border-top: 1px solid #cccccc;
                a {
                    color: #333;
                    font-weight: bold;
                }
                &:first-child {
                    border-top: none;
                }
                // border-bottom: 1px solid #CCCCCC;
            }
        }
    }
    .cus_navigation-drawer {
        .el-drawer__header {
            border-bottom: 1px solid #ccc;
            margin-bottom: 5px;
            padding-bottom: 15px;
        }
    }
    .header {
        width: 100%;
        height: 45px;
        z-index: inherit;
        margin: 0;
        padding: 0;
        background: #fff;
        .header-inside {
            margin: 0;
            padding: 0;
            padding-top: 10px;
            display: block;
            height: 45px;
            width: 100%;
        }
        &__left {
            display: block;
            position: relative;
            width: 100%;
        }

        .header-logo {
            margin: 0 auto;
            .logo__bg {
                background-position: center center;
            }
            .stock__info {
                text-align: center;
            }
        }

        .mobile-menu {
            position: absolute;
            left: 21px;
            width: 20px;
            height: 12px;
        }

        .navigation {
            &-child {
                &__block {
                    position: relative;
                    left: auto;
                    width: auto;
                    padding: 5vw 3vw;
                    top: auto;
                    transform: translate(0, 0);
                    text-align: left;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
